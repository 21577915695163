import { useEffect, useState } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { MyButton, MyLoading } from "../../../components";
import { ChangePassword, GetUser } from "../../../redux/Actions/Users";

const Setting = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const {data : profile} = useSelector((state)=>state.GetUser);
  const [loading, setLoading] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isVisible2, setVisible2] = useState(false);
  const [isVisible3, setVisible3] = useState(false);

  useEffect(()=>{
    dispatch(GetUser({id : props?.auth?.user_id},setLoading))
  },[props])

  const processUpdate = (data) => {
    dispatch(ChangePassword({user_id : props?.auth?.user_id, old_password : data.old_password, new_password : data.password, token : props?.auth?.token}, setLoading, history))
  }

  return (
    <Col className="pt-5 vh-100">
      <div className="ms-4 w-50">
        <Form onSubmit={handleSubmit(processUpdate)}>
          <Form.Group className="mb-3">
            <Form.Label className="text-blue fw-bolder">
              Old Password
            </Form.Label>
            <InputGroup>
              <Form.Control
                id="input-password"
                type={(!isVisible)?'password' : 'text'}
                placeholder="Password"
                {...register("old_password", {
                  required: true,
                  minLength: 8,
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                })}
                className={`border-radius-left border-end-0 py-3 ${
                  errors.old_password ? "is-invalid" : ""
                }`}
              />
              <InputGroup.Text className="border-radius-right bg-transparent px-0 border-start-0">
                {!isVisible ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icon/icon_close_eyes.svg`}
                    className="mx-2"
                    alt=""
                    onClick={() => setVisible(true)}
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icon/open_eyes.svg`}
                    className="mx-2"
                    alt=""
                    onClick={() => setVisible(false)}
                  />
                )}
              </InputGroup.Text>
            </InputGroup>
            <small className="text-danger fw-bold mt-2 ms-3">
              {errors.old_password &&
                "Password must contains *Min 8 character *Min. 1 capital letters *Number"}
            </small>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="text-blue fw-bolder">
              New Password
            </Form.Label>
            <InputGroup>
              <Form.Control
                id="input-password"
                type={(!isVisible2)?"password":"text"}
                placeholder="Password"
                {...register("password", {
                  required: true,
                  minLength: 8,
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                })}
                className={`border-radius-left border-end-0 py-3 ${
                  errors.password ? "is-invalid" : ""
                }`}
              />
              <InputGroup.Text className="border-radius-right bg-transparent px-0 border-start-0">
                {!isVisible2 ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icon/icon_close_eyes.svg`}
                    className="mx-2"
                    alt=""
                    onClick={() => setVisible2(true)}
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icon/open_eyes.svg`}
                    className="mx-2"
                    alt=""
                    onClick={() => setVisible2(false)}
                  />
                )}
              </InputGroup.Text>
            </InputGroup>
            <small className="text-danger fw-bold mt-2 ms-3">
              {errors.password &&
                "Password must contains *Min 8 character *Min. 1 capital letters *Number"}
            </small>
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label className="text-blue fw-bolder">
              Confirm Password
            </Form.Label>
            <InputGroup>
              <Form.Control
                type={(!isVisible3)?'password':'text'}
                placeholder="Type here"
                className={`border-radius-left border-end-0 py-3 ${
                  errors.re_password ? "is-invalid" : ""
                }`}
                {...register("re_password", {
                  required: true,
                  validate: (value) => {
                    return value === watch("password");
                  },
                })}
              />
              <InputGroup.Text className="border-radius-right bg-transparent px-0 border-start-0">
                {!isVisible3 ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icon/icon_close_eyes.svg`}
                    className="mx-2"
                    alt=""
                    onClick={() => setVisible3(true)}
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icon/open_eyes.svg`}
                    className="mx-2"
                    alt=""
                    onClick={() => setVisible3(false)}
                  />
                )}
              </InputGroup.Text>
            </InputGroup>
            <small className="text-danger fw-bold mt-2 ms-3">
              {errors.re_password && "Password not match"}
            </small>
          </Form.Group>
          <MyButton.btnBlue type="submit" value="Submit" style="py-3 w-50 float-end my-3" type='submit'/>
        </Form>
      </div>
      <MyLoading loading={loading} setLoading={setLoading}/>   
    </Col>
  );
};

export default Setting;
