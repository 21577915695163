import { Card, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Navigator,MyBody, Detail, CardView } from "./Components";
/* import { SendMessage } from "../../redux/Actions/Qiscus"; */

const Frame = () => {
  const [data, setData] = useState({
    msg: null,
  });

	const [menu, setMenu] = useState(1);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [cardShow, setCardShow] = useState(false);
  const [detail, setDetail] = useState([]);
  const [detailCard, setDetailCard]= useState([]);
  const { data: channel } = useSelector((state) => state.JoinChannel);
	const {data : authData} = useSelector((state)=>state.UserLogin);

  const sendChat = (e) => {
    e.preventDefault();
    if (data.msg.length > 0) {
      const newBody = {
        room_id: `${channel.id}`,
        message: data.msg,
        unique_id: `${Date.now()}`,
        type: "text",
        payload: {},
        extras: {},
      };
      /* dispatch(SendMessage(newBody, setLoading)) */
      setData({ ...data, msg: "" });
    }
  }

  return (
    <div id="chatFrame">
      <Row className="position-absolute bottom-0 start-0 w-100">
        <Col xs={12} md={5} className="mx-md-3 mx-1">
					{/* Header for back to previous page */}
          <div className="d-flex mt-3 mb-2 w-100">
            <Link to="/">
              <Card className="rounded-pill p-3 me-2 border-blue">
                <img
                  src={process.env.PUBLIC_URL + "/assets/icon/icon_back.svg"}
                  className="img-fluid"
                  alt=""
                />
              </Card>
            </Link>
            <Card className="rounded-pill p-2 w-100">
              <span className="text-center open-sans text-blue fw-bold my-auto open-sans text-w-8">
                Networking Lounge
              </span>
            </Card>
          </div>
					{/***/}
					{/* Navigator Panel*/}
					<Card className="rounded-30 bg-white vh-65 shadow-sm mb-3" id="component">
      			<Card.Body className="py-0 px-md-2 px-0 content main-session open-sans overflow-hidden">
        			<Navigator menu={menu} setMenu={setMenu} />
              <MyBody menu={menu} setShow={setShow} callback={setDetail} callback2={setDetailCard} setCardShow={setCardShow}/>
      			</Card.Body>
    			</Card>
					{/***/}
					{/* Send Message Panel */}
          <Card
            className="rounded-pill p-0 shadow-sm"
          >
            <Form onSubmit={(e) => sendChat(e)}>
              <Card.Body className="d-flex flex-row bd-highlight">
                <img
                  src={process.env.PUBLIC_URL + "/assets/icon/icon_chat.svg"}
                  className="me-2"
                  alt=""
                />
                <input
                  type="text"
                  placeholder="Type here to chat in Main Session"
                  className="form-control bg-light rounded-pill border-0 py-2"
                  value={data.msg}
                  onChange={(e) => setData({ ...data, msg: e.target.value })}
                />
              </Card.Body>
            </Form>
          </Card>
					{/***/}
        </Col>
        <Col className='w-25'>
          <Detail show={show} setShow={setShow} data={detail} state={0} auth={authData}/>
          <CardView show={cardShow} setShow={setCardShow} data={detailCard}/>
        </Col>
      </Row>
    </div>
  );
};

export default Frame;
