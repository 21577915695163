/* eslint-disable react/jsx-pascal-case */
import { MyNavbar, MyButton, MyFooter, MyLoading } from "../../../components";
import "../../../styles/auth.css";
import { UserLogin } from "../../../redux/Actions/Auth";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {Button} from "react-bootstrap";
import { useForm } from "react-hook-form";

const Login = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);
  const [isVisible, setVisible] = useState(false);


  const processLogin = (data) => {
    dispatch(UserLogin(data, setLoading));
  };
  return (
    <>
      <MyNavbar />
      <Container fluid className="mt-5 p-0 vh-100">
        <Row className="w-100 p-0">
          <Col xs={0} md={5} className="d-none d-md-block">
            <div className="side-img">
              <div className="cover"></div>
            </div>
          </Col>
          <Col
            xs={12}
            md={7}
            className="d-flex justify-content-center align-items-center open-sans p-0 p-md-5">
            <div className="p-0 w-100 mx-auto px-0 ms-4 mx-md-5 mt-md-0 mt-5 mb-5 mb-md-0">
              <div className="mb-3">
                <h3 className="fw-bolder">Welcome!</h3>
                <span>
                  Don’t have account yet? <Link to="/register" className='text-blue fw-bolder no-style text-w-800'>Register</Link>
                </span>
              </div>
              <Form onSubmit={handleSubmit(processLogin)}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="text-blue fw-bolder">
                    Email address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    {...register("email", {required : true})}
                    className={`rounded-pill py-3 ${errors.email ? 'is-invalid' : ''}`}
                  />
                   <small className='text-danger fw-bold mt-2 ms-3'>
                    {
                      errors.email?.type === 'required' && "Email is required"  || errors.email?.type ==='pattern' && 'Format email invalid'
                      }
                    </small>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="text-blue fw-bolder">
                    Password
                  </Form.Label>
                  <InputGroup>
                  <Form.Control
                    id='input-password'
                    type={(!isVisible)?"password":"text"}
                    placeholder="Password"
                    {...register("password", {required : true, minLength : 8, pattern : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/})}
                    className={`border-radius-left border-end-0 py-3 ${errors.password ? 'is-invalid' : ''}`}
                  />
                  <InputGroup.Text className='border-radius-right bg-transparent px-0 border-start-0'>
                    {!isVisible ? (
                      <img src={`${process.env.PUBLIC_URL}/assets/icon/icon_close_eyes.svg`} className='mx-2' alt='' onClick={() => setVisible(true)}/>
                    ) : (
                      <img src={`${process.env.PUBLIC_URL}/assets/icon/open_eyes.svg`} className='mx-2' alt='' onClick={() => setVisible(false)}/>
                    )}
                    </InputGroup.Text>
                  </InputGroup>
                  <small className='text-danger fw-bold mt-2 ms-3'>
                    {
                      errors.password && 'Password must contains *Min 8 character *Min. 1 capital letters *Number'
                    }
                    </small>
                </Form.Group>
                <div className="d-flex justify-content-end my-3">
                    <Link to='/forgot-password'>
                      <small className="fw-bold float-end text-blue me-3">
                        Forgot password?
                      </small>
                  </Link>
                </div>
                <div className="d-flex justify-content-center">
                  <MyButton.btnBlue
                    type='submit'
                    value="Login"
                    style="py-3 w-50"
                  />
                </div>
              </Form>
              <div className="d-flex flex-column justify-content-center mt-5 mb-3">
                <div className="line mb-3">
                  <small className='px-3 text-blue bg-white'>or continue with</small>
                </div>
              </div>
              <center>
              <Button className='py-2 px-4 bg-transparent border-blue'>
                <img src={`${process.env.PUBLIC_URL}/assets/icon/icon_google.svg`} alt=''/>
              </Button>
              </center>
            </div>
          </Col>
        </Row>
        <hr className='m-0'/>
        <MyFooter/>
        <MyLoading loading={loading} setLoading={setLoading}/>   
      </Container>     
    </>
  );
};

export default Login;
