import { Card, Form } from "react-bootstrap";
import {Link} from 'react-router-dom'
const ChatFrame = (props) => {
  return (
    <>
      <div className="position-absolute top-50 start-0 translate-middle ms-5">
			<Link to='/' className='no-style'>
				<Card className='rounded-pill px-3 py-2 d-flex flex-row ms-5'>
					<span className='me-2 text-blue align-self-center open-sans fw-bold'>EXIT</span>
					<img src={`${process.env.PUBLIC_URL}/assets/icon/icon_exit.svg`} alt='' className='icon-md'/>
				</Card>
			</Link>
			</div>
      <div className="position-absolute bottom-0 end-0 mb-2 me-2">
        <Card className="rounded-pill p-0 mb-1 shadow-sm w-100 me-5">
          <Form>
            <Card.Body className="d-flex flex-row bd-highlight">
              <img
                src={process.env.PUBLIC_URL + "/assets/icon/icon_chat.svg"}
                className="me-2"
                alt=""
              />
              <input
                type="text"
                placeholder="Type here to chat in virtual room"
                className="form-control bg-light rounded-pill border-0 "
              />
            </Card.Body>
          </Form>
        </Card>
      </div>
    </>
  );
};
export default ChatFrame;
