import {Form} from 'react-bootstrap';
import {useState} from 'react';
import PhoneInput from 'react-phone-input-2';
import { useEffect } from 'react';
import moment from 'moment';

const FormEdit1 = (props) => {
  const [loading, setLoading] = useState(false);


  return (
    <>
      <Form.Group className="d-flex flex-row m-0">
        <Form.Label className="text-blue fw-bolder">Gender</Form.Label>
        <div className="mx-auto">
          <div className="form-check form-check-inline mx-3">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              checked={(props?.profile.user_gender != null && props?.profile?.user_gender =='Male') ? true : false }
              onChange={(e)=>{props.setData({...props?.profile, user_gender : e.target.value})}}
              value="Male"
            />
            <label className="form-check-label" for="inlineRadio1">
              Male
            </label>
          </div>
          <div className="form-check form-check-inline mx-3">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              checked={(props?.profile?.user_gender != null && props?.profile?.user_gender =='Female') ? true : false }
              onChange={(e)=>{props.setData({...props?.profile, user_gender : e.target.value})}}
              value="Female"
            />
            <label className="form-check-label" for="inlineRadio2">
              Female
            </label>
          </div>
        </div>
      </Form.Group>

      <Form.Group controlId="formBasicPassword" className="my-2">
        <Form.Label className="text-blue fw-bolder">Email</Form.Label>
        <Form.Control
          type="email"
          onChange={(e)=>{props.setData({...props?.profile, email : e.target.value})}}
          value={props?.profile?.email}
          placeholder="Type Here"
          className={`rounded-pill py-3`}
        />
      </Form.Group>

      <Form.Group className="my-2">
        <Form.Label className="text-blue fw-bolder">Date of Birth</Form.Label>
        <Form.Control
          type="date"
          value={moment(props?.profile?.user_dob).format('YYYY-MM-DD')}
          onChange={(e)=>{props.setData({...props?.profile, user_dob : e.target.value})}}
          className={`rounded-pill py-3`}
        />
      </Form.Group>

      <Form.Group className="my-2">
        <Form.Label className="text-blue fw-bolder">Phone Number</Form.Label>
        <PhoneInput
          countryCodeEditable={false}
          autoFormat={true}
          country={"id"}
          buttonClass={`border-0 bg-transparent`}
          placeholde="Enter phone number"
          value={props?.profile?.user_phone}
          onChange={(e)=>{props.setData({...props?.profile, user_phone : e})}}
          defaultErrorMessage="Must number phone !"
          autocompleteSearch={true}
          priority={{ id: 0 }}
          copyNumbersOnly={false}
          prefix="+"
          inputClass={`rounded-pill py-4 w-100`}
        />
      </Form.Group>

      <Form.Group className="my-2">
        <Form.Label className="text-blue fw-bolder">Linkedin</Form.Label>
        <Form.Control
          onChange={(e) => props?.setData({ ...props?.profile, user_linkedin_link: e.target.value })}
          defaultValue={props?.profile?.user_linkedin_link}
          type="text"
          placeholder="Type here"
          className={`rounded-pill py-3`}
        />
      </Form.Group>

      <Form.Group controlId="formBasicEmail">
        <Form.Label className="text-blue fw-bolder">Company</Form.Label>
        <Form.Control
          defaultValue={props?.profile?.user_company_name}
          onChange={(e)=>props?.setData({...props?.profile, user_company_name : e.target.value})}
          type="text"
          placeholder="Type here"
          className={`rounded-pill py-3`}
        />
      </Form.Group>
    </>
  );
};

export default FormEdit1;
