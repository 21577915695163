import { useEffect, useState } from "react"
import Lobby from "./Lobby";
import Participants from "./Participants";
import MyConnection from "./MyConnection";
import MyChat from "./MyChat";
import { useSelector } from "react-redux";

const MyBody = (props)=>{
    const { data: authData } = useSelector((state) => state.UserLogin);
    const [show, setShow] = useState(props.setShow);
    const [card, showCard] = useState(props.setCardShow);

    useEffect(()=>{
        setShow(props.setShow)
        showCard(props.setCardShow);
    },[props])

    useEffect(()=>{
        props.setShow(show);
        props.setCardShow(card);
    },[show, card])

    return(
        <div className='h-100 w-100 pb-3'>
        {(props.menu == 1)?(
            <Lobby auth={authData}/>
        ):(props.menu==2)?(
            <Participants auth={authData} callback={props.callback} setShow = {props.setShow} />
        ):(props.menu==3)?(
            <MyConnection auth={authData} setShow={props.setCardShow} callback={props.callback2} />
        ):(
            <MyChat auth={authData}/>
        )}
        </div>
    )
}
export default MyBody