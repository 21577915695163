import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetConference } from "../../../redux/Actions/Home";

const Canvas = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {data : conference} = useSelector((state)=>state.GetConference);

  useEffect(()=>{
    dispatch(GetConference({event_id : props?.auth?.event_id}, setLoading))
  },[props])

  return (
    <div className="vh-100 banner4 w-100 mx-0 px-0">
      <div className='d-flex justify-content-center align-items-center open-sans h-100'>
          <iframe src={conference?.roomConferenceHall?.room_link_video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" className='w-50 h-30 card p-1' allowfullscreen />
      </div>
    </div>
  );
};

export default Canvas;
