import { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import MyCalendar from "./MyCalendar";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  BookAgenda,
  GetAgenda,
  GetCalendar,
} from "../../../redux/Actions/Home";
import {useHistory} from 'react-router-dom'
import { GetBookUser } from "../../../redux/Actions/Users";

const Agenda = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dateNow, setDateNow] = useState(null);
  const [loading, setLoading] = useState(false);
  const { data: auth } = useSelector((state) => state.UserLogin);
  const { data: agenda } = useSelector((state) => state.GetAgenda);
  const { data: calendar } = useSelector((state) => state.GetCalendar);
  const { data: schedule } = useSelector((state) => state.GetBookUser);

  useEffect(() => {
    dispatch(
      GetAgenda(
        { event_id: props?.event_id, date: moment().format("YYYY-MM-DD") },
        setLoading
      )
    );
    dispatch(GetCalendar({ event_id: props?.event_id }, setLoading));
    dispatch(GetBookUser({ id: auth?.user_id }, setLoading));
  }, []);

  useEffect(() => {
    if (dateNow != null) {
      dispatch(
        GetAgenda(
          {
            event_id: props?.event_id,
            date: moment(dateNow, "YYYY-MM-DD").format("YYYY-MM-DD"),
          },
          setLoading
        )
      );
    }
  }, [dateNow]);

  const checkEvent = (event_schedule_id) => {
    for (let i = 0; i < schedule.length; i++) {
      if (schedule.event_schedule_id == event_schedule_id) {
        return true;
      }
    }
  };

  return (
    <>
      <div
        className={`${
          props?.show ? "d-block" : "d-none"
        } position-absolute top-50 start-50 translate-middle w-75 h-60`}
        id="agenda"
      >
        <Card className="w-90 rounded-30 bg-light">
          <Row className="w-100 h-100">
            <Col xs={12} md={5} className="p-0 py-md-3 px-md-4">
              <Card className="rounded-pill p-2 mb-2">
                <div className="d-flex justify-content-center flex-row">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icon/icon_calendar.svg`}
                    alt=""
                    className="icon"
                  />
                  <h5 className="fw-bold open-sans text-blue ms-2 align-self-center my-auto">
                    DATE
                  </h5>
                </div>
              </Card>
              <Card className="p-3 rounded-30">
                <MyCalendar
                  event_id={1}
                  event={agenda}
                  calendar={calendar}
                  setDate={setDateNow}
                />
              </Card>
            </Col>
            <Col xs={7} className="py-3">
              <Card className="h-100 rounded-30 p-3">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row">
                    <div className="mx-auto w-50">
                      <h5 className="text-center text-blue fw-bold">
                        EVENT SCHEDULE
                      </h5>
                      <hr />
                    </div>
                  </div>
                  {loading ? (
                    <span className="text-blue text-center">Loading...</span>
                  ) : agenda.length > 0 ? (
                    agenda.map((element) => (
                      <div className="p-2 d-flex flex-row border border-top-0 border-start-0 border-end-0">
                        <div className="me-1">
                          <small className="text-blue fw-bold">
                            {moment(element?.event_start_time, "h-mm-a").format(
                              "LT"
                            )}{" "}
                            -{" "}
                            {moment(element?.event_end_time, "h-mm-a").format(
                              "LT"
                            )}
                          </small>
                        </div>
                        <div className="d-flex flex-column">
                          <span className="text-blue">
                            : {element?.event_name}
                          </span>
                          <div className="d-flex flex-row">
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/icon/profile_grey.svg`}
                              alt=""
                              className="icon ms-1"
                            />
                            <small className="ms-2 align-self-center text-blue">
                              {element?.organizer_name},{" "}
                              {element?.organizer_job}
                            </small>
                          </div>
                        </div>
                        {checkEvent(element?.event_schedule_id) ? (
                          <></>
                        ) : (
                          <div className="d-flex flex-column justify-content-center ms-auto">
                            <button
                              className="btn text-center btn-blue rounded-pill px-4"
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(
                                  BookAgenda(
                                    {
                                      user_id: auth?.user_id,
                                      event_schedule_id:
                                        element?.event_schedule_id,
                                      token: auth?.token,
                                    },
                                    setLoading, history
                                  )
                                );
                              }}
                            >
                              <small>BOOK</small>
                            </button>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <span className="text-blue text-center">
                      no events today
                    </span>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
          <div className='position-absolute top-0 end-0 p-2'>
              <img
                src={`${process.env.PUBLIC_URL}/assets/icon/icon_minimize.svg`}
                alt=""
                className='cursor-pointer'
                onClick={()=>{props.setShow(false)}}
              />
            </div>
        </Card>
      </div>
    </>
  );
};

export default Agenda;
