/* eslint-disable react/jsx-pascal-case */
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { MyNavbar, MyButton, MyFooter } from '../../../components';
import {UserForgotPassword} from '../../../redux/Actions/Users'
import { useState } from 'react';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const processForgot = (data) => {
	/* 	Swal.fire({
			icon : 'success',
			title : 'Sent',
			text : 'Please Chek Your Email',
			confirmButtonText:'Confirm',
			customClass: {
				confirmButton: 'btn btn-outline-blue fw-bolder open-sans rounded-pill py-2 px-4 border-0',
			},
			buttonsStyling: false
		}
		) */
    const newData = {email : data.email};
    dispatch(UserForgotPassword(newData, setLoading))
  };
  return (
    <>
      <MyNavbar />
      <Container fluid className="mt-5 vh-100 p-0">
        <Row className="w-100 p-0">
          <Col xs={0} md={5} className="d-none d-md-block">
            <div className="side-img">
              <div className="cover"></div>
            </div>
          </Col>
          <Col
            xs={12}
            md={7}
            className="d-flex flex-column open-sans p-md-5"
          >
            <div className="p-0 w-100 mx-auto px-0 ms-4 mx-md-3 mt-5">
              <h2 className='fw-bolder '>Forgot Password?</h2>
              <span>Enter the email address associated with your account</span>
              <Form onSubmit={handleSubmit(processForgot)} className='mt-5'>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="text-blue fw-bolder">
                    Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    {...register("email", { required: true, minLength: 13 })}
                    className={`rounded-pill py-3 ${
                      errors.email ? "is-invalid" : ""
                    }`}
                  />
                  <small className="text-danger fw-bold mt-2 ms-3">
                    {errors.email?.type === "required" && "Email is required"}
                  </small>
                </Form.Group>
								<div className="d-flex justify-content-center">
                  <MyButton.btnBlue
                    type='submit'
                    value="Send"
                    style="py-3 w-25"
                  />
                </div>
              </Form>
            </div>
          </Col>
        </Row>
        <hr className="m-0" />
        <MyFooter/>
      </Container>
    </>
  );
};

export default ForgotPassword;
