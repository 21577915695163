import axios from "axios";
import qiscus from "../../qiscus";
import { CreateUser } from "./Qiscus";
import Swal from "sweetalert2";

const UserLoginRequest = () => {
  return { type: "USER_LOGIN_REQUEST" };
};
const UserLoginSuccess = (data) => {
  return { type: "USER_LOGIN_SUCCESS", payload: data };
};
const UserLoginError = (err) => {
  return { type: "USER_LOGIN_ERROR", payload: err };
};
export const UserLogout = () => {
  qiscus.disconnect();
  return { type: "USER_LOGOUT" };
};

export const UserLogin = (formData, cb) => {
  return (dispatch) => {
    cb(true);
    dispatch(UserLoginRequest());
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}user/login`,
      data: formData,
    })
      .then((res) => {
        if (res.data.statusCode == 200) {
          cb(false);
          Swal.fire({
            icon: "success",
            text: `${res.data.statusMessage}`,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton:
                "btn btn-blue fw-bolder open-sans rounded-pill py-2 px-4 border-0",
            },
            buttonsStyling: false,
          }).then((result)=>{
            if(result.isConfirmed){
              dispatch(UserLoginSuccess(res.data.data));
            }
          });
        } else {
          dispatch(UserLoginError(res.data));
          cb(false);
          Swal.fire({
            icon: "error",
            text: `${res.data.statusMessage}`,
            confirmButtonText: "Confirm",
            customClass: {
              confirmButton:
                "btn btn-danger fw-bolder open-sans rounded-pill py-2 px-4 border-0",
            },
            buttonsStyling: false,
          });
        }
      })
      .catch((err) => {
        dispatch(UserLoginError(err));
        Swal.fire({
          icon: "error",
          text: `${err}`,
          confirmButtonText: "Confirm",
          customClass: {
            confirmButton:
              "btn btn-danger fw-bolder open-sans rounded-pill py-2 px-4 border-0",
          },
          buttonsStyling: false,
        });

        cb(false);
      });
  };
};

const UserRegisterRequest = () => {
  return { type: "USER_REGISTER_REQUEST" };
};
const UserRegisterSuccess = (data) => {
  return { type: "USER_REGISTER_SUCCESS", payload: data };
};
const UserRegisterError = (err) => {
  return { type: "USER_REGISTER_Error", payload: err };
};

export const UserRegister = (formData, cb, cb2) => {
  return (dispatch) => {
    cb(true);
    dispatch(UserRegisterRequest());
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}user/register`,
      data: formData,
    })
      .then((res) => {
        if (res.data.statusCode == 200) {
          dispatch(UserRegisterSuccess(res.data));
          cb(false);
          Swal.fire({
            icon: "success",
            title : 'Check Your Email !',
            text: `${res.data.statusMessage}`,
            confirmButtonText: "Confirm",
            customClass: {
              confirmButton:
                "btn btn-blue fw-bolder open-sans rounded-pill py-2 px-4 border-0",
            },
            buttonsStyling: false,
          }).then((result)=>{
            if(result.isConfirmed){
                cb2.replace('/');
            }
          });
        } else {
          dispatch(UserRegisterError(res.data));
          cb(false);
          Swal.fire({
            icon: "error",
            text: `${res.data.statusMessage}`,
            confirmButtonText: "Confirm",
            customClass: {
              confirmButton:
                "btn btn-danger fw-bolder open-sans rounded-pill py-2 px-4 border-0",
            },
            buttonsStyling: false,
          })
        }
      })
      .catch((err) => {
        alert(err);
        dispatch(UserRegisterError(err));
        cb(false);
      });
  };
};
