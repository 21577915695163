import { Form, InputGroup } from "react-bootstrap"

const MyChat = () =>{
    return(
        <div className='m-2'>
        <Form.Group
     controlId="validationFormikUsername"
     className="rounded-pill bg-custom-grey my-3"
   >
     <InputGroup>
       <InputGroup.Text
         id="inputGroupPrepend"
         className="bg-transparent border-0"
       >
         <img
           src={`${process.env.PUBLIC_URL}/assets/icon/icon_search.svg`}
           className="icon p-2"
         />
       </InputGroup.Text>
       <Form.Control
         type="text"
         placeholder="Search chat"
         aria-describedby="inputGroupPrepend"
         className="border-0 bg-custom-grey"
         name="username"
       />
       <InputGroup.Text
         id="inputGroupPrepend"
         className="bg-transparent border-0"
       >
         <img
           src={`${process.env.PUBLIC_URL}/assets/icon/icon_filter.svg`}
           className="icon rounded-circle bg-custom-blue p-2"
         />
       </InputGroup.Text>
     </InputGroup>
   </Form.Group>
         <span className="text-blue fw-bolder open-sans">My chat</span>
     </div>
    )
}

export default MyChat