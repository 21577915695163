import {Modal, Spinner} from 'react-bootstrap';
const MyLoading = (props)=>{
    return(
        <Modal
        className='bg-transparent'
        show={props.loading}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={(e) => props.setLoading(false)}
        backdrop='static'>
        <Modal.Body className='m-5 text-center'>
          <Spinner animation="border" variant="primary" className="loading" />
        </Modal.Body>
      </Modal>
    )
}

export default MyLoading