import { Button } from "react-bootstrap";

const MyButton = {
  btnBlue: ({ value = "submit", style, ...buttonProps}) => {
    return (
        <Button
          className={`btn btn-blue fw-bolder open-sans rounded-pill py-2 px-4 border-0 ${style}`}
          type="button"
          {...buttonProps}
        >
          {value}
        </Button>
    );
  },
  btnBlueOutline: ({ value = "submit", style, ...buttonProps}) => {
    return (
      <Button
        className={`btn btn-outline-blue fw-bolder open-sans rounded-pill py-2 px-4 ${style}`}
        type="button"
        {...buttonProps}
      >
        {value}
      </Button>
    );
  },
};

export default MyButton;
