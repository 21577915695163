import { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import {useSelector, useDispatch} from 'react-redux';
import {GetParticipiant, LoadCommentChannel, JoinChannel} from '../../../redux/Actions/Qiscus';
import moment from "moment";
import greeter from "../../../emitter";

const ChatList = (props) => {

  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState([]);
  const { isLogin, data : authData } = useSelector((state) => state.UserLogin);
  const {data : comment} = useSelector((state)=>state.LoadCommentChannel);
  const {data : channel} = useSelector((state)=>state.JoinChannel);

  greeter.once('new-message', async( newData )=>{
    if(newData[0].room_id == channel.id){
      await setMsg([...msg, ...newData]);
    }
  });

  useEffect(() => {
    setMsg([...comment]);
    const component = document.getElementById("component");
    if (show) {
      component.style.display = "block";
    } else {
      component.style.display = "none";
      props.cb(false);
      props.cb2(false);
    }
  }, [show]);

  useEffect(()=>{
    setShow(props.show);
    dispatch(LoadCommentChannel(channel.id))
  },[props])

  const handler = (index) => {
    props.cb2(true);
    props.cb3(msg[index]);
  };

  return(
    <>
<Card
  className="rounded-40 bg-white vh-65 shadow-sm mb-3 overflow-hidden"
  id="component"
>
  <Card.Header className="bg-transparent d-flex flex-row">
    <h5 className="fw-bold me-auto">Main Session</h5>
    <img
      src={`${process.env.PUBLIC_URL}/assets/icon/icon_minimize.svg`}
      alt=""
      onClick={() => setShow(false)}
    />
  </Card.Header>
  <Card.Body className="p-0 content main-session pb-5">
    {msg&&
      msg.map((element, index) =>
      element.email != authData?.data?.id_user? (
        <div className="d-flex bd-highlight mb-3">
          <div className="p-2 bd-highlight" onClick={() => handler(index)}>
            {element.active ? (
              <img
                src={`${process.env.PUBLIC_URL}/assets/icon/icon_online.svg`}
                alt=""
                className="icon"
              />
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/assets/icon/icon_offline.svg`}
                alt=""
                className="icon"
              />
            )}
          </div>
          <div className="p-2 bd-highlight">
            <div>
              <small>{element.username}</small> |{" "}
              <small className="text-muted">{element.position}</small>
            </div>
            <div className='d-flex align-items-end'>
              <span className="card shadow-sm p-2 mx-w-30 message-left me-2">
                {element.message}
              </span>
              <small>{moment(element.timestamp).format('LT')}</small>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-row flex-row-reverse mb-3 me-3">
          <div className='d-flex align-items-end'>
            <small>{moment(element.timestamp).format('LT')}</small>
            <span className="card border-blue shadow-sm p-2 mx-w-30 message-right ms-2">
              {element.message}
            </span>
          </div>
        </div>
      )
    )}
  </Card.Body>
</Card>
    </>
)
};

export default ChatList;
