import QiscusSDKCore from 'qiscus-sdk-core';
import greeter from '../emitter';

const qiscus = new QiscusSDKCore();
qiscus.init({
    // change this into your own AppId through https://dashboard.qiscus.com
    AppId: process.env.REACT_APP_QISCUS_ID,
    options: {
        loginSuccessCallback: function(authData) {
          console.log('ini login',authData);
          showDesktopNotification(authData);
            this.presenceCallback(authData, authData.user.email)
            greeter.emit('event', {kind: 'login-success', data: authData});
        },
    		loginErrorCallback(data) {},
        commentDeletedCallback: function (data) {},
        commentDeliveredCallback: function (data) {
          console.log('coment diterima');
        },
        commentReadCallback: function (data) {},
        presenceCallback: function (data, userId) {

        const isOnline = data[0] === '1';
        const lastOnline = new Date(Number(data[1]));
        greeter.emit('event', {
          kind: 'online-presence',
          data: {isOnline, lastOnline},
        });
        },
        typingCallback: function (data) {
          console.log('typing', data);
        },
        onReconnectCallback: function (data) {},
        newMessagesCallback: async(messages)=>{
          await greeter.emit('new-message', messages);
        },
        roomClearedCallback: function (data) {},
        roomChangedCallback: function (data) {
          console.log('room is changed');
        } 
    }
});
function showDesktopNotification (data) {

   // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    // If it's okay let's create a notification
    var username = data.user.username
    var useravatar = data.user.avatar
    var notification = new Notification('You get a new message from ' + username, {
      icon: useravatar,
      body: username
    })
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        var username = data.user.username
        var useravatar = data.user.avatar
        var notification = new Notification('You get a new message from ' + username, {
          icon: useravatar,
          body: username
        })
      }
    });
  }
}

export default qiscus