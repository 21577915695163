/* eslint-disable react/jsx-pascal-case */
import { Button, Col, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import {MyButton, MyLoading} from '../../../../components'
import {useState, useEffect, useRef} from 'react'
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { UserRegister } from "../../../../redux/Actions/Auth";

const RegistrationThree = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const ref = useRef();
  const [loading, setLoading] = useState(false);
  const [isConfirm, setConfirm] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [modifiedData, setModifiedData] = useState({
    name_card: []
  });

  useEffect(()=>{
    let poster = document.getElementById("poster");
    let btnAdd = document.getElementById('add');
    let btnDelete = document.getElementById('delete');
    if(modifiedData.name_card.length < 1){
      poster.style.display = 'none';
      btnDelete.style.display = 'none';
      btnAdd.style.display = 'block';
    }else{
      poster.style.display = 'block';
      btnAdd.style.display = 'none';
      btnDelete.style.display = 'block';
    }
  },[modifiedData])

  const handleChange = (e) => {
    let poster = document.getElementById("poster");
    poster.src = URL.createObjectURL(e.target.files[0]);
    setModifiedData({
      ...modifiedData,
      name_card: e.target.files,
    });

    poster.onload = function () {
      URL.revokeObjectURL(poster.src);
    };
  };
  const handleChecked = (e)=>{
    if(e.target.checked){
      setConfirm(true);
    }else{
      setConfirm(false);
    }
  }
  const processRegister = (data) => {
    props.setData({ ...props.data, password: data.password, re_password: data.re_password, linkedin : data.linkedin});
    const formData = new FormData();
    const image = modifiedData.name_card[0];
    formData.append("email", props.data.email);
    formData.append("password", props.data.password ?? data.password);
    formData.append('user_linkedin_link', props.data.linkedin ?? data.linkedin);
    formData.append("user_name", props.data.name);
    formData.append("user_gender", props.data.gender);
    formData.append("user_dob", props.data.birth);
    formData.append("user_company_name", props.data.company);
    formData.append("user_job_name", props.data.job);
    formData.append("user_position_name", props.data.position);
    formData.append('user_salary', props.data.income);
    formData.append('user_spending', props.data.spending);
    formData.append('user_card_image', image);
    formData.append('user_phone', `+${props.data.phone}`);

    dispatch(UserRegister(formData, setLoading, history));
  };

  const handleDelete = (e) =>{
    e.preventDefault();
    setModifiedData({...modifiedData, name_card : []});
    ref.current.value = "";
  }
  return (
    <>
      <div className="d-flex flex-row me-auto mt-5 mt-md-3">
        <Button
          className="bg-transparent border-0"
          onClick={() => props.setStage(2)}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/icon/icon_back_arrow.svg`}
            alt=""
            className="icon-lg"
          />
        </Button>
        <h3 className="d-flex align-items-center fw-bold">
          Create your Account
        </h3>
      </div>
      <div className="p-0 w-100 px-0 px-md-5 px-3 my-3 row">
        <Col xs={12} md={12}>
          <Form onSubmit={handleSubmit(processRegister)}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className='text-blue '><span className="fw-bolder">Upload Name Card</span><small> (optional)</small></Form.Label>
							<br/>
              <div class='d-flex col'>
                  <img
                      src=".."
                      alt="..."
                      className="me-3 align-content-center background w-25 h-25"
                      id="poster"
                    />

							  <div className='btn btn-sm rounded-30 align-self-center p-3 btn-outline-blue me-3' id='add'>
                  <label>
                    <Form.Control
                	    type="file"
                      ref={ref}
                	    className="rounded-pill py-3"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e)=>handleChange(e)}
              	    />
								    <img src={`${process.env.PUBLIC_URL}/assets/icon/icon_upload.svg`} alt='' className='me-3 icon'/>
								    <span className='fw-bolder'>Upload</span>
                  </label>
							  </div>

                <Button className='btn btn-sm rounded-30 px-4 btn-outline-blue align-self-center py-2' id='delete' onClick={(e)=>{handleDelete(e)}}>
                  <label>
								    <span className='fw-bolder'>Delete</span>
                  </label>
							  </Button>

              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label className="text-blue fw-bolder">Linkedin</Form.Label>
              <Form.Control
                {...register("linkedin", {required : true})}
                onChange={(e)=>props.setData({...props.data, linkedin : e.target.value})}
                defaultValue={props.data.linkedin}
                type="text"
                placeholder="Type here"
                className={`rounded-pill py-3 ${errors.linkedin? 'is-invalid' : ''}`}
              />
              	<small className='text-danger fw-bold mt-2 ms-3'>
                    {
                      errors.linkedin && 'Password must contains *Min 8 character *Min. 1 capital letters *Number'
                    }
                </small>
            </Form.Group>

            <Form.Group>
              <Form.Label className="text-blue fw-bolder">Password</Form.Label>
              <Form.Control
                {...register("password", {required : true, minLength : 8, pattern : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/ })}
                type="password"
                onChange={(e)=>props.setData({...props.data, password : e.target.value})}
                defaultValue={props.data.password}
                placeholder="Type here"
                className={`rounded-pill py-3 ${errors.password ? 'is-invalid' : ''}`}
              />
              	<small className='text-danger fw-bold mt-2 ms-3'>
                    {
                      errors.password && 'Password must contains *Min 8 character *Min. 1 capital letters *Number'
                    }
                </small>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label className="text-blue fw-bolder">Confirm Password</Form.Label>
							<Form.Control
                type="password"
                placeholder="Type here"
                className={`rounded-pill py-3 ${errors.re_password ? 'is-invalid' : ''}`}
                {...register("re_password",{required : true, validate : (value)=>{return value=== watch('password');}})}
              />
              	<small className='text-danger fw-bold mt-2 ms-3'>
                    {
                      errors.re_password && 'Password not match'
                    }
                    </small>
            </Form.Group>
						
						<div className="mb-3 form-check">
    					<input type="checkbox" className="form-check-input rounded-circle" id="exampleCheck1" {...register('confirm', {required:true})} onChange={(e)=>handleChecked(e)}/>
    					<label className="form-check-label" for="exampleCheck1"><span className='text-blue'>I have read and agree to the </span><Link to='/' className='fw-bold'>Terms and Conditions</Link></label>
  					</div>
						<div className="d-flex justify-content-center">
                  {(!isConfirm) ? (
                    <MyButton.btnBlue
                      type="submit"
                      value="Submit"
                      style="py-3 w-50"
                      disabled
                  />
                  ):(
                    <MyButton.btnBlue
                    type="submit"
                    value="Submit"
                    style="py-3 w-50"
                  />
                  )}
            </div>
          </Form>
        </Col>
        <Col xs={2} className="me-auto d-flex align-items-center float-end">

        </Col>
      </div>
      <MyLoading loading = {loading} setLoading = {setLoading} />
    </>
  );
};

export default RegistrationThree
