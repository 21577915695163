import { useEffect, useState } from "react";
import { Card, Container, Form, InputGroup} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MyNavbar, MyFooter} from "../../components";
import { GetAllBooth } from "../../redux/Actions/Booth";
import { GetHome } from "../../redux/Actions/Home";
import Canvas from "./Canvas";

const ExhibitionHall = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { data: home } = useSelector((state) => state.GetHome);
  const { data : booth } = useSelector((state)=>state.GetAllBooth);
  const {data : authData} = useSelector((state)=>state.UserLogin);

  useEffect(() => {
    dispatch(GetHome({ id_event: authData?.event_id}, setLoading));
    dispatch(GetAllBooth(authData?.event_id, setLoading));
  }, []);
  
  return (
    <>
      <MyNavbar />
      <Container fluid className="p-0">
        <div className="vh-100">
          <div className="h-100">
            <Canvas booth={booth}/>
            <div className="position-absolute top-0 start-0 mt-5">
              <div className="pt-5 ps-2 d-flex">
                <Link to="/" className='bg-custom-blue p-2 rounded-circle align-self-center'>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icon/icon_back_to.svg"
                    }
                    className="icon-sm rounded-circle m-2"
                    alt=""
                  />
                </Link>
                <h3 className="align-self-center fw-bold open-sans text-blue ms-2">
                  BACK TO MAIN HALL
                </h3>
              </div>
            </div>
            <div className="position-absolute top-0 end-0 pt-5">
              <Card className="rounded-30 bg-white mt-5 me-3 p-2">
                <Form.Group
                  controlId="validationFormikUsername"
                  className="rounded-pill bg-custom-grey my-3 mx-2"
                >
                  <InputGroup>
                    <InputGroup.Text
                      id="inputGroupPrepend"
                      className="bg-transparent border-0"
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/icon/icon_search.svg`}
                        className="icon p-2"
												alt=''
                      />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Search chat"
                      aria-describedby="inputGroupPrepend"
                      className="border-0 bg-custom-grey border-radius-right"
                      name="username"
                    />
                  </InputGroup>
                </Form.Group>
								<span className='text-blue fw-bold mx-2'>Booth List</span>
                <div className='p-2'>
                {booth?.boothExhibitionHall &&
                  booth?.boothExhibitionHall.map((element, index)=>(
                  <div className='d-flex flex-row'>
                    <img src={`${process.env.PUBLIC_URL}/assets/icon/icon_booth.svg`} alt='' className='icon'/>
                    <span className='text-blue align-self-center ms-2'>{element.booth_name}</span>
                    <Link to={`/booth?no_booth=${element.room_id}`} className='ms-auto'>
                    <span className='btn btn-sm btn-blue rounded-pill px-3'>Visit</span>
                    </Link>
                  </div>
                ))}
                </div>
              </Card>
            </div>
          </div>
          <MyFooter data={home?.data?.data?.footer} />
        </div>
      </Container>
    </>
  );
};

export default ExhibitionHall;
