import { useEffect } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

const RegistrationTwo = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const processRegister = (data) => {
    props.setData({ ...props.data, company: data.company, job: data.job, position : data.position, income : data.income, spending : data.spending });
    props.setStage(3);
  };

  const job = [
    { id: 1, name: "Finance" },
    { id: 2, name: "Marketing" },
    { id: 3, name: "IT Support" },
    { id: 4, name: "Data Analyst" },
  ];

  const pcc = [
    { id: 1, name: "CEO" },
    { id: 2, name: "IT-ENGINERING" },
    { id: 3, name: "DEVELOPER" },
    { id: 4, name: "SYSTEM ANALYST" },
  ];

  const income = [
    { id: 1, name: ">10.000.000" },
    { id: 2, name: "7.000.000 < 10.000" },
    { id: 3, name: "4.000.000 < 7.000.000" },
    { id: 4, name: "<4.000.000" },
  ];
  return (
    <>
      <div className="d-flex flex-row me-auto my-5 my-md-3">
        <Button
          className="bg-transparent border-0"
          onClick={() => props.setStage(1)}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/icon/icon_back_arrow.svg`}
            alt=""
            className="icon-lg"
          />
        </Button>
        <h3 className="d-flex align-items-center fw-bold">
          Create your Account
        </h3>
      </div>
      <Form onSubmit={handleSubmit(processRegister)} className="w-100">
        <div className="p-0 px-0 me-auto px-md-5 px-3 row">
          <Col xs={10} md={10}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="text-blue fw-bolder">Company</Form.Label>
              <Form.Control
                {...register("company", { required: true })}
                defaultValue={props.data.company}
                type="text"
                placeholder="Type here"
                className={`rounded-pill py-3 ${
                  errors.company ? "is-invalid" : ""
                }`}
              />
              <small className="text-danger fw-bold ms-3">
                {errors.company?.type === "required" && "Company is required"}
              </small>
            </Form.Group>

            <Form.Group>
              <Form.Label className="text-blue fw-bolder">Job</Form.Label>
              <Form.Select
                defaultValue={props?.data?.job}
                className={`rounded-pill py-3 ${
                  errors.job ? "is-invalid" : ""
                }`}
                {...register("job", { required: true, minLength: 1 })}
              >
                <option value="">Choose</option>
                {job.map &&
                  job.map((element) => (
                    <option value={element.name}>{element.name}</option>
                  ))}
                <option value="">Other : </option>
              </Form.Select>
              <small className="text-danger fw-bold ms-3">
                {errors.job?.type === "required" && "Job is required"}
              </small>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label className="text-blue fw-bolder">Position</Form.Label>
              <Form.Select
                defaultValue={props?.data?.position}
                className={`rounded-pill py-3 ${
                  errors.position ? "is-invalid" : ""
                }`}
                {...register("position", { required: true, minLength: 1 })}
              >
                <option value="">Choose</option>
                {pcc.map &&
                  pcc.map((element) => (
                    <option value={element.name}>{element.name}</option>
                  ))}
                <option value="">Other : </option>
              </Form.Select>
              <small className="text-danger fw-bold mt-2 ms-3">
                {errors.position?.type === "required" && "Position is required"}
              </small>
            </Form.Group>

            <Form.Group>
              <Form.Label className="text-blue fw-bolder">Income</Form.Label>
              <Form.Select
                defaultValue={props?.data?.income}
                className={`rounded-pill py-3 ${
                  errors.income ? "is-invalid" : ""
                }`}
                {...register("income", { required: true, minLength: 1 })}
              >
                <option value="">Choose</option>
                {income.map &&
                  income.map((element) => (
                    <option value={element.name}>{element.name}</option>
                  ))}
                <option value="">Other : </option>
              </Form.Select>
              <small className="text-danger fw-bold mt-2 ms-3">
                {errors.income?.type === "required" && "Income is required"}
              </small>
            </Form.Group>

            <Form.Group>
              <Form.Label className="text-blue fw-bolder">Spending</Form.Label>
              <Form.Select
                defaultValue={props?.data?.spending}
                className="rounded-pill py-3"
                className={`rounded-pill py-3 ${
                  errors.spending ? "is-invalid" : ""
                }`}
                {...register("spending", { required: true, minLength: 1 })}
              >
                <option value=''>Choose</option>
                {income.map &&
                  income.map((element) => (
                    <option value={element.name}>{element.name}</option>
                  ))}
                <option value="">Other : </option>
              </Form.Select>
              <small className="text-danger fw-bold ms-3">
                {errors.spending?.type === "required" && "Spending is required"}
              </small>
            </Form.Group>
          </Col>
          <Col xs={2} className="me-auto d-flex align-items-center float-end">
            <div className="d-flex flex-column">
              <Button className="bg-transparent border-0" type="submit">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icon/icon_back_arrow_white.svg`}
                  alt=""
                  className="rounded-circle bg-custom-blue icon-lg p-3"
                />
              </Button>
              <span className="align-self-center d-none d-md-block">
                Next
              </span>
            </div>
          </Col>
        </div>
      </Form>
    </>
  );
};

export default RegistrationTwo;
