/* eslint-disable react/jsx-pascal-case */
import { Container, Navbar } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import MyButton from "../MyButton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserLogin, UserLogout } from "../../redux/Actions/Auth";

const MyNavbar = () => {
  const history = useHistory();
  const { isLogin, data } = useSelector((state) => state.UserLogin);
  const [login, setLogin] = useState(isLogin);

  useEffect(() => {
    setLogin(isLogin);
  }, [isLogin]);
  const dispatch = useDispatch();
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="light"
        fixed="top"
        className="shadow-sm"
      >
        <Container fluid>
          <Link to="/">
            <Navbar.Brand>
              <img
                alt=""
                src={process.env.PUBLIC_URL + "/assets/icon/icon_bank.svg"}
                className="d-inline-block align-top img-fluid"
              />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <div className="d-flex justify-content-end w-100">
              {!login ? (
                <>
                  <Link to="/login">
                    <MyButton.btnBlueOutline
                      style={`me-md-3 me-2`}
                      value="Login"
                    />
                  </Link>
                  <Link to="/register">
                    <MyButton.btnBlue value="Register" />
                  </Link>
                </>
              ) : (
                <>
                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/icon/icon_notification.svg"
                      }
                      className="me-4 my-auto icon"
                      alt=""
                    />
                  </div>
                  <Link to='/profile'>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/icon/icon_profile.svg"
                    }
                    className="me-3 my-auto icon"
                    alt=""
                  />
                  </Link>
                </>
              )}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default MyNavbar;
