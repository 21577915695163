import { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  GetConnections,
  ActionRequest,
} from "../../../../../redux/Actions/Users";

const MyConnection = (props) => {
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data: connections } = useSelector((state) => state.GetConnections);

  useEffect(() => {
    dispatch(GetConnections(props.auth.user_id, setLoading));
  }, [update]);

  const handleClick = (status, id) => {
    const newData = { user_connection_id: id, status: status };
    dispatch(ActionRequest(newData, setLoading, setUpdate));
  };

  const clickCard = (index)=>{
    /* console.log(connections?.connectionAccept[id]) */
    props.setShow(true);
    props.callback(connections?.connectionAccept[index]);
  }

  const clickCard2 = (index)=>{
    props.setShow(true);
    props.callback(connections?.connectionWaiting[index]);
  }
  return (
    <div className="mx-2 h-100">
      <Form.Group
        controlId="validationFormikUsername"
        className="rounded-pill bg-custom-grey my-3"
      >
        <InputGroup>
          <InputGroup.Text
            id="inputGroupPrepend"
            className="bg-transparent border-0"
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/icon/icon_search.svg`}
              className="icon p-2"
              alt=""
            />
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Search connection"
            aria-describedby="inputGroupPrepend"
            className="border-0 bg-custom-grey"
            name="username"
          />
          <InputGroup.Text
            id="inputGroupPrepend"
            className="bg-transparent border-0"
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/icon/icon_filter.svg`}
              className="icon rounded-circle bg-custom-blue p-2"
              alt=""
            />
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
      <span className="text-blue fw-bolder open-sans">Connection Request</span>
      <div className="d-flex flex-column h-25 overflow-scroll">
        {connections?.connectionWaiting &&
          connections?.connectionWaiting?.map((item, index) => (
            <div className="d-flex bd-highlight mb-3 open-sans">
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icon/icon_chat.svg`}
                  alt=""
                />
              </div>
              <div className="align-self-center ms-2 w-50">
                <span className="fw-bold">{item.user_send_request}</span>-{" "}
                <small>{item.user_position_name} - {item.user_company_name}</small>
              </div>
              <div className="ms-auto">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icon/icon_card.svg`}
                  alt=""
                  onClick={()=>{clickCard2(index)}}
                />
                <button
                  className="btn btn-blue btn-sm rounded-pill mx-3"
                  onClick={() => handleClick(true, item.user_connection_id)}
                >
                  Accept
                </button>
                <button
                  className="btn btn-outline-secondary btn-sm rounded-pill"
                  onClick={() => handleClick(false, item.user_connection_id)}
                >
                  Ignore
                </button>
              </div>
            </div>
          ))}
      </div>
      <span className="text-blue fw-bolder open-sans">My Connection</span>
      <div className="d-flex flex-column h-25 overflow-scroll">
        {connections?.connectionAccept &&
          connections?.connectionAccept?.map((item, index) => (
            <div className="d-flex bd-highlight mb-3 open-sans">
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icon/icon_chat.svg`}
                  alt=""
                />
              </div>
              <div className="align-self-center ms-2">
                <span className="fw-bold">{item.user_send_request}</span>-{" "}
                <small>{item.user_position_name ?? "none"} -{" "}</small>
                <small>{item.user_company_name ?? "none"}</small>
              </div>
              <div className="ms-auto d-flex">
                <div className="me-4">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icon/chat.svg`}
                    alt=""
                  />
                </div>
                <div className="me-4">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icon/icon_card.svg`}
                    alt=""
                    onClick={()=>clickCard(index)}
                  />
                </div>
                <div className="me-4">
                  <Link
                    to={{ pathname: `${item?.user?.linkedin_link ?? 'https://www.linkedin.com/'}` }}
                    target="_blank"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icon/icon_linkedin.svg`}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </div>
      
    </div>
  );
};

export default MyConnection;
