const Canvas = () =>{
     return(
         <div className='h-90 d-flex justify-content-center ms-5'>
            <div style={{ 
                backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/properties/booth.png'})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize:'cover'
            }}
            className='w-100 h-75 align-self-center'>
            </div>
         </div>
     )
}

export default Canvas