import { combineReducers } from "redux";
import {
  UserLogin,
  UserRegister,
} from "./Auth";
import { UserForgotPassword, CheckEmail, UpdatePasswordUser, GetConnections, ActionRequest, GetUser, GetBookUser, ChangePassword, UpdateProfile} from "./Users";
import { GetHome, GetAgenda, GetCalendar, BookAgenda, GetConference} from "./Home";
import { JoinChannel, CreateUser, SendMessage, LoadCommentChannel, AddParticipiant, GetParticipiants} from "./Qiscus";
import { GetAllBooth, GetBooth } from "./Booth";
import { GetParticipants, SendRequest } from "./Chatroom";

const reducers = combineReducers({
  UserLogin,
  UserRegister,
  JoinChannel,
  CreateUser,
  SendMessage,
  LoadCommentChannel,
  AddParticipiant,
  GetParticipiants,
  GetHome,
  UserForgotPassword,
  CheckEmail,
  UpdatePasswordUser,
  GetParticipants,
  SendRequest,
  GetConnections,
  ActionRequest,
  SendRequest,
  GetAllBooth,
  GetAgenda,
  GetUser,
  GetCalendar,
  GetBooth,
  BookAgenda,
  GetBookUser,
  ChangePassword,
  UpdateProfile,
  GetConference
});

export default reducers;
