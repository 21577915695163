import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import {saveAs} from 'file-saver';

const CardView = (props) => {
  const [show, setShow] = useState(props.show);
  const [display, setDisplay] = useState("d-none");

  useEffect(() => {
    props.show ? setShow(true) : setShow(false);
  }, [props]);

  useEffect(() => {
    show ? setDisplay("d-block") : setDisplay("d-none");
		console.log('ini data',props.data);
  }, [props]);

  const downloadImage = (url, nm_img)=>{
    saveAs(url, 'image.jpg');
  }

  return <Card className={`${display} h-60 w-75`}>
  <center className='my-auto'>
    <img src={props?.data?.user_card_image} className='img-fluid my-3' alt=''/><br/>
    <button onClick={()=>{downloadImage(props?.data?.user_card_image, props?.data?.user_card_image)}} className='btn btn-blue rounded-pill'>
      Download
    </button>
  </center>
	</Card>;
};

export default CardView;
