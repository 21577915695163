import {
  BrowserRouter as Router, //router atau jalan ke arah mana
  Switch, // cabang antar halaman
  Route,
} from "react-router-dom";
import { Dashboard, Login, Networking, NotFound, Profile, Register, ForgotPassword, UpdatePassword, ConferenceHall, ExhibitionHall, Booth} from "./pages";
import React, {useState} from 'react'
import { PrivateRoute, PublicRoute} from "./components";
import { Provider } from 'react-redux'
import configureStore from './redux/store'
import { PersistGate } from "redux-persist/integration/react";

const {store, persistor} = configureStore()


const AppRouter = () =>{
  return(
    <Router>
      <Switch>
        <PublicRoute path="/login" exact={true} restricted={true} component={()=><Login/>}/>
        <PublicRoute path="/forgot-password" exact={true} restricted={true} component={()=><ForgotPassword/>}/>
        <PublicRoute path="/change-password" exact={true} restricted={true} component={()=><UpdatePassword/>}/>
        <PublicRoute path="/register" exact={true} restricted={true} component={()=><Register/>}/>
        <PrivateRoute path="/networking-lounge" exact={true} component={()=><Networking/>}/>
        <PrivateRoute path="/booth" exact={true} component={()=><Booth/>}/>
        <PrivateRoute path="/conference-hall" exact={true} component={()=><ConferenceHall/>}/>
        <PrivateRoute path="/exhibition-hall" exact={true} component={()=><ExhibitionHall/>}/>
        <PrivateRoute path="/profile" exact={true} component={()=><Profile/>}/>
        <Route path="/" exact={true} component={()=><Dashboard />} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  )
}

export default function App() {
  return(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRouter />
      </PersistGate>
    </Provider>
  )
}