import moment from "moment";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { GetAgenda } from "../../../redux/Actions/Home";
const MyCalendar = (props) => {

  let dateRow = [];
  let row = [];
  const [schedule, setSchedule] = useState(moment());
  for (let i = 1; i <= schedule.daysInMonth(); i++) {
    let day = moment(schedule).set("date", i);
    let weekday = moment(day).day();

    switch (weekday) {
      case 0:
        weekday = 6;
        break;
      case 1:
        weekday = 0;
        break;
      case 2:
        weekday = 1;
        break;
      case 3:
        weekday = 2;
        break;
      case 4:
        weekday = 3;
        break;
      case 5:
        weekday = 4;
        break;
      case 6:
        weekday = 5;
        break;
      default:
        break;
    }

    row[weekday] = day;
    if (weekday == 6) {
      dateRow.push(row);
      row = [];
    }
  }

  if (row.length > 0) {
    dateRow.push(row);
  }

  useEffect(()=>{
    console.log('ini event',props.event.length)
    console.log('ini agenda', props.calendar);
  },[])

  const checkEvent = (date)=>{
    for(let i =0 ; i < props.calendar.length ; i++){
      if(moment(date,'YYYY-MM-DD').format('YYYY-MM-DD') === moment(props.calendar[i].date, 'YYYY-MM-DD').format('YYYY-MM-DD')){
        return true;
      }
    }
  }

  return (
    <div className="w-100 h-100 open-sans">
      <div className="d-flex justify-content-between">
        <h5 className="align-self-center text-blue fw-bolder">
          {moment(schedule).format("MMMM YYYY")}
        </h5>
        <div className="d-flex flex-row">
          <div
            onClick={() => setSchedule(moment(schedule).add(-1, "month"))}
            className="me-3"
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/icon/left.svg`}
              className="icon-sm"
              alt=""
            />
          </div>
          <div onClick={() => setSchedule(moment(schedule).add(1, "month"))}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/icon/right.svg`}
              className="icon-sm"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="w-100 my-1">
        <div className="d-flex flex-row justify-content-between text-center text-blue fw-bold">
          <div className="px-2 w-100">Mo</div>
          <div className="px-2 w-100">Tu</div>
          <div className="px-2 w-100">We</div>
          <div className="px-2 w-100">Th</div>
          <div className="px-2 w-100">Fr</div>
          <div className="px-2 w-100">Sa</div>
          <div className="px-2 w-100">Su</div>
        </div>
				<div className='d-flex flex-column w-100 h-100'>
        {dateRow &&
          dateRow?.map((item, index) => (
            <div className="d-flex flex-row justify-content-between text-center w-100">
              {[...Array(7)].map((_, subindex) => (
                  <div className={`${item[subindex]!=null ? 'border' : ''} cursor-pointer text-center m-2 w-100 h-100 ${(checkEvent(moment(item[subindex]).format('YYYY-MM-DD')) && item[subindex]!=null) ? 'bg-custom-blue text-white' : ''}`}
                  onClick={()=>props.setDate(moment(item[subindex]).format('YYYY-MM-DD'))}
                  >
                  <small className="m-2 d-block">
                    {item[subindex] != null
                      ? moment(item[subindex]).format("DD")
                      : ""}
                  </small>
                </div>
              ))}
            </div>
          ))}
					</div>
      </div>
    </div>
  );
};

export default MyCalendar;
