import { useSelector } from "react-redux"
import { Redirect, Route } from "react-router"
import React from 'react';

const PublicRoute = ({component: Component, restricted, ...rest})=>{
    const {isLogin} = useSelector((state)=>state.UserLogin)
    return(
        <Route {...rest} render = {(props)=> isLogin && restricted ? <Redirect to='/'/> 
        : <Component {...props} />} />
    )
}
export default PublicRoute