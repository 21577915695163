import { Container } from "react-bootstrap";
import {  MyFooter, MyNavbar, NetworkingFrame } from "../../components";
import Frame from "./Frame";
import Canvas from "./Canvas";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { CreateUser, JoinChannel } from "../../redux/Actions/Qiscus";
import qiscus from '../../qiscus';

const Networking = () =>{
    const dispatch = useDispatch();
    const {data : authData, isLogin} = useSelector((state)=>state.UserLogin);
    const user = useSelector((state)=>state.CreateUser);

    useEffect(()=>{
      const networkingLanguage = {uniqueId : process.env.REACT_APP_NETWORKING_ID, name : 'networking language', avatarURL : 'empty'}
      dispatch(CreateUser(authData, networkingLanguage));
    },[isLogin])
    return (
        <>
          <MyNavbar/>
          <Container fluid className='mt-5 p-0 vh-100'>
            <div className="h-100">
              <Canvas/>
              <Frame/>
            </div>
            <hr className='txt-blue fw-bold m-0'/>
            <MyFooter/>
          </Container>
        </>
      );
}

export default Networking
