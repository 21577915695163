import { MyNavbar} from "../../components";
import Canvas from './Canvas';
import ChatFrame from "./ChatFrame";
import "../../styles/dashboard.css";
import { UserLogin } from "../../redux/Actions/Auth";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Container, Row } from "react-bootstrap";
import qiscus from '../../qiscus';
import xs from 'xstream';
import { useEffect, useState } from "react";
import { CreateUser, GetParticipiant } from "../../redux/Actions/Qiscus";
import {GetHome } from '../../redux/Actions/Home';
import { MyFooter } from "../../components";
import {Fade} from 'react-animation-components';
import { Transform } from 'react-animation-components'
import Agenda from "./Agenda";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { isLogin, data : authData } = useSelector((state) => state.UserLogin);
  /* const [loading, setLoading] = useState(false); */
  const user =  useSelector((state)=>state.CreateUser);
  const {data : home} = useSelector((state)=>state.GetHome);
  const [showAgenda, setShowAgenda] = useState(false);
  
  useEffect(()=>{
    const mainSession = {uniqueId : process.env.REACT_APP_CHANNEL_ID, name : 'main session', avatarURL : 'emoty'}
    dispatch(CreateUser(authData, mainSession));
    dispatch(GetHome({id_event : authData?.event_id}))
  },[isLogin])

  return (
    <>
      <MyNavbar/>
      <Container fluid className='vh-100 p-0'>
        <div className="w-100">
          <Canvas data={home} setShow={setShowAgenda} show={showAgenda}/>
          <Transform enterTransform="translateX(0)" exitTransform="translateX(-400px)" in>
            <ChatFrame isLogin={isLogin} data = {authData} auth = {user}/>
          </Transform>
          <div className="line my-5">
            <span className='bg-custom-blue py-3 rounded-30 text-white px-5 text-blue fw-bold'>OUR SPONSORS</span>
          </div>
          <Row className='w-100 mx-auto mb-5 px-5'>
            {home?.data?.data?.sponsor && home?.data?.data?.sponsor?.map((element)=>(
              <Col md={2} xs={6} className='card border-0 px-auto'>
                <img src={`${element.sponsor_logo}`} className='sponsor mx-auto' alt=''/>
              </Col>
            ))}
          </Row>
          <Agenda show={showAgenda} setShow={setShowAgenda} event_id={authData?.event_id}/>
          <MyFooter data={home?.data?.data?.footer}/>
        </div>
      </Container>
    </>
  );
};

export default Dashboard;
