import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { MyNavbar, MyFooter} from "../../components";
import { GetHome } from "../../redux/Actions/Home";
import { CreateUser } from "../../redux/Actions/Qiscus";
import Canvas from "./Canvas";
import ChatFrame from "./ChatFrame";
import SideBar from "./SideBar";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ConferenceHall = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const [loading, setLoading] = useState(false);
  const { data: home } = useSelector((state) => state.GetHome);
  const user = useSelector((state) => state.CreateUser);
  const { isLogin, data: authData } = useSelector((state) => state.UserLogin);

  useEffect(() => {
    const conferenceHall = {
      uniqueId: process.env.REACT_APP_CHANNEL_ID,
      name: "main session",
      avatarURL: "emoty",
    };
    dispatch(CreateUser(authData, conferenceHall));
    dispatch(GetHome({ id_event: 1 }, setLoading));
  }, []);

  return (
    <>
      <MyNavbar />
      <Container fluid className="vh-100 p-0">
        <div className="w-100">
          <Row className="m-0 h-100">
            <Col
              xs={12}
              md={3}
              className="bg-white p-0 border border-start-0 border-bottom-0 shadow-sm py-5"
            >
             <SideBar auth={authData}/>
            </Col>
            <Col xs={12} md={9} className="p-0">
              <div className="w-100 position-relative">
                <Canvas auth={authData}/>
                <ChatFrame auth={authData}/>
              </div>
            </Col>
          </Row>
          <MyFooter data={home?.data?.data?.footer} />
        </div>
      </Container>
    </>
  );
};

export default ConferenceHall;
