import { Card, Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

const Canvas = (props)=>{
    return (
        <div className="vh-100 w-100 overflow-hidden">
          {/* Tammpilan xl device */}
          <div className="my-5 banner h-95 d-none d-md-block">
            <div className="position-absolute z-4 d-flex row w-55 h-25 ps-5 ms-5 py-3">
              <center>
                {props?.data?.data?.data?.video &&
                  props?.data?.data?.data?.video.map((element) => (
                    <embed
                      src={`${element.video_link}`}
                      frameborder="1"
                      title="video"
                      className="w-35 h-40 border-blue p-2 pe-0"
                    />
                  ))}
              </center>
            </div>
            <Row className="h-45 pt-md-4 m-0">
              <Col md={9} className="h-100 pt-1">
                <Link to="./networking-lounge">
                  <Card className="bg-transparent h-100 w-40 float-end border-0" title='networking lounge'/>
                </Link>
              </Col>
            </Row>
            <Row className="h-50 m-0">
              <Col md={5} className="h-100 p-0">
                <Link to="./conference-hall">
                  <Card className="bg-transparent h-50 w-40 float-end border-0" title='conference hall'/>
                </Link>
              </Col>
              <Col md={5} className="h-100 ps-5">
                <Link to="./exhibition-hall">
                  <Card className="bg-transparent h-45 ms-5 w-60 float-start border-0" title='exhibition hall'/>
                </Link>
                <Link to="./help-desk">
                  <Card className="bg-transparent h-45 w-60 me-4 float-end border-0" title='help desk'/>
                </Link>
              </Col>
              <Col md={2} className="h-100">
                <Card className="bg-transparent h-100 w-50 float-start border-0 cursor-pointer" title='agenda' onClick={()=>{(!props.show) ? props.setShow(true) : props.setShow(false)}}/>
              </Col>
            </Row>
          </div>
          {/* Tampilan medium - large device */}
          {/* <div className="my-5 banner h-95 d-md-block d-xl-none">
            <div className="position-absolute z-4 d-flex row w-55 h-25 ps-5 ms-5 py-3">
              <center>
                {props?.data?.data?.data?.video &&
                  props?.data?.data?.data?.video.map((element) => (
                    <embed
                      src={`${element.video_link}`}
                      frameborder="1"
                      title="video"
                      className="w-35 h-40 border-blue p-2 pe-0"
                    />
                  ))}
              </center>
            </div>
            <Row className="h-45 pt-md-4 m-0">
              <Col md={5} className="h-100"></Col>
              <Col md={7} className="h-100 pt-1 ps-5">
                <Link to="./networking-lounge">
                  <Card className="bg-transparent h-100 w-50 float-start" />
                </Link>
              </Col>
            </Row>
            <Row className="h-50 m-0">
              <Col md={5} className="h-100 p-0">
                <Link to="./conference-hall">
                  <Card className="bg-transparent h-50 w-40 float-end" />
                </Link>
              </Col>
              <Col md={5} className="h-100 ps-5">
                <Link to="./exhibition-hall">
                  <Card className="bg-transparent h-45 ms-5 w-60 float-start" />
                </Link>
                <Link to="./help-desk">
                  <Card className="bg-transparent h-45 w-60 me-4 float-end" />
                </Link>
              </Col>
              <Col md={2} className="h-100">
                  <Link to="./agenda">
                    <Card className="bg-transparent h-100 w-50 float-start" />
                  </Link>
              </Col>
            </Row>
          </div>
     */}
        </div>
      )
}

export default Canvas