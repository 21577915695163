import { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import ChatList from "./ChatList";
import AddRequest from "./AddRequest";
import { useDispatch, useSelector } from "react-redux";

import {
  JoinChannel,
  GetParticipiant,
  LoadCommentChannel,
  SendMessage
} from "../../../redux/Actions/Qiscus";
import {UserLogin} from '../../../redux/Actions/Auth';

const ChatFrame = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState(props.isLogin);
  const [session, setSession] = useState(false);
  const [detailView, setDetailView] = useState(false);
  const [detail, setDetail] = useState({});
  const {data : channel} = useSelector((state)=>state.JoinChannel);

  const [data, setData] = useState({
    msg: '',
  });
  useEffect(() => {
    setLogin(props.isLogin);
  }, [props]);

  useEffect(() => {
    const component = document.getElementById("chatFrame");
    if (login) {
      component.style.display = "block";
    } else {
      component.style.display = "none";
    }
  }, [login]);

  const sendChat = (e) => {
    e.preventDefault();
    if(data.msg.length > 0){
      const newBody = {
        room_id : `${channel.id}`,
        message : data.msg,
        unique_id : `${Date.now()}`,
        type : 'text',
        payload : {},
        extras : {}
      }
      dispatch(SendMessage(newBody, setLoading))
      setData({...data, msg : ''})
    }
  };
  
  return (
    <div className="w-100" id="chatFrame">
      <Row className="position-absolute z-5 bottom-0 start-0 w-100 mb-3">
        <Col xs={12} md={4} className="open-sans ms-2 mx-md-3">
          <ChatList
            show={session}
            cb={setSession}
            cb2={setDetailView}
            cb3={setDetail}
          />
          <Card
            className="rounded-pill p-0 mb-1 shadow-sm"
            onClick={() => setSession(true)}
          >
            <Form onSubmit={(e)=>sendChat(e)}>
              <Card.Body className="d-flex flex-row bd-highlight">
                <img
                  src={process.env.PUBLIC_URL + "/assets/icon/icon_chat.svg"}
                  className="me-2"
                  alt=""
                />
                <input
                  type="text"
                  placeholder="Type here to chat in Main Session"
                  className="form-control bg-light rounded-pill border-0 "
                  value={data.msg}
                  onChange={(e) => setData({ ...data, msg: e.target.value })}
                />
              </Card.Body>
            </Form>
          </Card>
        </Col>
        <Col xs={12} md={4} className="open-sans ms-2 mx-md-0">
          <AddRequest show={detailView} data={detail} state={0} />
        </Col>
      </Row>
    </div>
  );
};

export default ChatFrame;
