import { Container } from "react-bootstrap"

const NotFound = () =>{
    return(
        <Container fluid>
            <div className='d-flex justify-content-center align-items-center vh-100'>
                <div class="d-flex justify-content-start">
                    <h1> 404 |</h1><p className='text-muted align-self-end'>Page Not Found</p>
                </div>
            </div>
        </Container>
    )
}

export default NotFound