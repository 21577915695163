const initialState = {
    data : [],
    loading : false
}

const GetHome = (state = initialState, action = {})=>{
    switch (action.type) {  
        case 'GET_HOME_SUCCESS':
            return {
                loading: false,
                data: action.payload
            }    
        case 'GET_HOME_ERROR': 
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}
const GetAgenda = (state = initialState, action = {})=>{
    switch (action.type) {  
        case 'GET_AGENDA_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'GET_AGENDA_ERROR': 
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}
const GetCalendar = (state = initialState, action = {})=>{
    switch(action.type){
        case 'GET_CALENDAR_SUCCESS':
            return{
                ...state,
                loading : false,
                data : action.payload
            }
        case 'GET_CALENDAR_FAILED':
            return{
                ...state,
                loading : false,
                error: action.payload,
                data : []
            }
        default :
            return state;
    }
}
const BookAgenda = (state = initialState, action = {})=>{
    switch(action.type){
        case 'BOOK_AGENDA_SUCCESS':
            return{
                ...state,
                loading:false,
                data : action.payload
            }
        case 'BOOK_AGENDA_FAILED':
            return{
                ...state,
                loading : false,
                error : action.payload,
                data : []
            }
        default :
            return state;
    }
}

const GetConference = (state = initialState, action = {})=>{
    switch(action.type){
        case 'GET_CONFERENCE_SUCCESS':
            return{
                ...state,
                loading:false,
                data : action.payload
            }
        case 'GET_CONFERENCE_FAILED':
            return{
                ...state,
                loading : false,
                error : action.payload,
                data : []
            }
        default :
            return state;
    }
}
export {GetHome, GetAgenda, GetCalendar, BookAgenda, GetConference}