import {Form} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import PhoneInput from 'react-phone-input-2';
import {UpdateProfile} from '../../../redux/Actions/Users';

/* email : props?.profile?.email, 
user_gender : props?.profile?.user_gender,
user_dob : props?.profile?.user_dob,
user_phone : props?.profile?.user_phone,
user_linkedin_link : props?.profile?.user_linkedin_link,
user_company_name : props?.profile?.user_company_name */



const FormEdit2 = (props) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const {data : auth} = useSelector((state)=>state.UserLogin);

  const processUpdate = (e) =>{
    e.preventDefault();
    const formData = new FormData();

    formData.append("user_position_name", props?.profile.user_position_name);
    formData.append("user_salary", props?.profile.user_salary);
    formData.append("user_spending", props?.profile.user_spending);
    formData.append("email", props?.profile?.email);
    formData.append("user_gender", props?.profile?.user_gender);
    formData.append("user_dob", props?.profile?.user_dob);
    formData.append("user_phone", `+${props?.profile?.user_phone}`);
    formData.append("user_linkedin_link", props?.profile?.user_linkedin_link);
    formData.append("user_company_name", props?.profile?.user_company_name);
    formData.append('user_name', props?.profile?.user_name);
    formData.append('user_job_name', props?.profile?.user_job_name);
    if(props?.profile?.name_card.length > 0){
      formData.append('user_card_image', props?.profile?.name_card[0]);
    }

    if(props?.profile?.photo.length > 0){
      formData.append('user_photo', props?.profile?.photo[0]);
    }
    
    dispatch(UpdateProfile(formData, auth?.token, auth?.user_id, setLoading))
  }
  return (
    <>
    <Form onSubmit={processUpdate}>
      <Form.Group controlId="formBasicPassword" className="my-2">
        <Form.Label className="text-blue fw-bolder">Position</Form.Label>
        <Form.Control
          type="text"
          defaultValue={props?.profile?.user_position_name}
          placeholder="Type Here"
          onChange={(e)=>{props.setData({...props?.profile, user_position_name : e.target.value})}}
          className={`rounded-pill py-3`}
        />
      </Form.Group>

      <Form.Group className="my-2">
        <Form.Label className="text-blue fw-bolder">Income</Form.Label>
        <Form.Control
          type="text"
          defaultValue={props?.profile?.user_salary}
          onChange={(e)=>{props.setData({...props?.profile, user_salary : e.target.value})}}
          className={`rounded-pill py-3`}
        />
      </Form.Group>

      <Form.Group className="my-2">
        <Form.Label className="text-blue fw-bolder">Spending</Form.Label>
        <Form.Control
          type="text"
          defaultValue={props?.profile?.user_spending}
          onChange={(e)=>{props.setData({...props?.profile, user_spending : e.target.value})}}
          className={`rounded-pill py-3`}
        />
      </Form.Group>
      <center>
      <button className='mx-auto btn btn-blue rounded-pill py-3 px-5 my-5' onClick={(e)=>{processUpdate(e)}}>
        <span className='fw-bolder mx-3'>Submit</span>
      </button>
      </center>
      </Form>
    </>
  );
};

export default FormEdit2;
