import { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { MyButton } from "../../../../components";
import { SendRequest } from "../../../../redux/Actions/Chatroom";

const Detail = (props) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(props.show);
  const [state, setState] = useState(props.state);
  const [display, setDisplay] = useState("d-none");
	const { data: authData } = useSelector((state) => state.UserLogin);
  const [noted, setNoted] = useState(null)

  useEffect(() => {
    props.show ? setShow(true) : setShow(false);
    setState(props.state);
  }, [props]);

  useEffect(() => {
    show ? setDisplay("d-block") : setDisplay("d-none");
  }, [props]);

	const handleRequest = (e)=>{
		e.preventDefault();
		const data = {
			user_id : props.data.user_id,
			sender_id : authData?.user_id,
			noted : noted ?? '',
		}
		dispatch(SendRequest(data, setLoading))
		console.log('ini data yang akan dikirim', data);
		props.setShow(false);
	}

  return (
    <div className={display}>
      <Card className="rounded-40 w-50">
        <Card.Body>
          <div className="d-flex flex-row bd-highlight mb-3">
            <div className="p-2 bd-highlight">
              <img
                src={`${process.env.PUBLIC_URL}/assets/icon/icon_online.svg`}
                alt=""
              />
            </div>
            <div className="p-2 bd-highlight w-100">
              <small className="fw-bolder">{props.data.user_name}</small>
              <hr className="m-0" />
              <small className="text-muted">
                {props.data.user_position_name ?? "none"} at{" "}
                {props.data.user_company_name}
              </small>
            </div>
          </div>
          {state == 0 ? (
            <center>
              <MyButton.btnBlue
                value="Connect"
                style="px-5"
                onClick={() => setState(1)}
              />
            </center>
          ) : state == 1 ? (
            <div>
              <center className="my-3">
                <div>
                  <span>
                    Add personal notes to{" "}
                    <span className="fw-bolder">{props.data.name}</span>
                  </span>
                </div>
              </center>
              <center className="d-flex justify-content-between">
                <MyButton.btnBlue value="Send" style="fw-100 mx-2" onClick={(e)=>handleRequest(e)}/>
                <MyButton.btnBlueOutline
                  value="Add Notes"
                  style="fw-100 mx-2"
                  onClick={() => setState(2)}
                />
              </center>
            </div>
          ) : (
						<div>
              <Form onSubmit={handleRequest}>
                <textarea placeholder='Type your message here' className='form-control mb-3 rounded-30' onChange={(e)=>setNoted(e.target.value)}/>
							  <center><MyButton.btnBlue value='Send' style='px-5' onClick={(e)=>handleRequest(e)}/></center>
              </Form>
						</div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default Detail;
