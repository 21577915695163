/* eslint-disable react/jsx-pascal-case */
import { Col, Container, Row } from "react-bootstrap";
import { MyFooter, MyNavbar } from "../../../components";
import { useEffect, useState } from "react";
import { RegistrationOne, RegistrationThree, RegistrationTwo } from "./Content";

const Register = () => {
	const [state, setState] = useState(1);
  const formData = new FormData();

  const [data, setData]=useState({
    name : '',
    gender : null,
    email : '',
    birth : '',
    phone : '',
    company : '',
    job : '',
    position : '',
    income : '',
    spending : '',
    password : null,
    linkedin : null
  })
  
  useEffect(()=>{
    console.log(formData);
  },[formData])

  useEffect(()=>{
    console.log('ini datanya',data);
  },[data])
  return (
    <>
      <MyNavbar />
      <Container fluid className="mt-5 vh-100 p-0">
        <Row className="w-100 p-0">
          <Col xs={0} md={5} className="d-none d-md-block">
            <div className="side-img">
              <div className="cover"></div>
            </div>
          </Col>
          <Col
            xs={12}
            md={7}
            className="d-flex justify-content-center flex-column align-items-center open-sans"
          >
						{(state == 1)?(
							<RegistrationOne setStage={setState} setData = {setData} data = {data}/>
						):(state == 2)?(
							<RegistrationTwo setStage={setState} setData = {setData} data = {data}/>
						):(
							<RegistrationThree setStage={setState} setData = {setData} data  = {data}/>
						)}
          </Col>
        </Row>
        <hr className="m-0" />
        <MyFooter/>
      </Container>
    </>
  );
};

export default Register;
