const initialState = {
    data : [],
    loading : false
}

export const GetParticipants = (state = initialState, action = {})=>{
    switch (action.type) {  
        case 'GET_PARTICIPANTS_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'GET_PARTICIPANTS_FAILED': 
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}

export const SendRequest = (state = initialState, action = {})=>{
    switch (action.type) {  
        case 'SEND_REQUEST_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'SEND_REQUEST_FAILED': 
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}
