import { MyNavbar, MyFooter } from "../../components";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Canvas } from "./Components";
import { useHistory, useLocation } from "react-router";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetBooth } from "../../redux/Actions/Booth";
import { Link } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Booth = () => {
  const query = useQuery();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: booth } = useSelector((state) => state.GetBooth);

  useEffect(() => {
    dispatch(GetBooth({ booth_id: query.get("no_booth") }, setLoading));
  }, []);
  console.log(booth);
  return (
    <>
      <MyNavbar />
      <Container fluid className="m-0 p-0 vh-100">
        <Row className="w-100 h-100">
          <Col xs={12} md={4} className="p-0">
            <div className="h-100 w-100 pt-5">
              <Card className="border-radius-20 w-100 m-5 h-90 p-3">
                <div className="d-flex w-100 flex-row">
                  <div
                    className="bg-custom-blue p-2 text-center rounded-circle align-self-start cursor-pointer"
                    onClick={history.goBack}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icon/icon_back_to.svg`}
                      alt=""
                      className="icon icon-sm m-2"
                    />
                  </div>
                  <div className="mx-2">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icon/icon_chat.svg`}
                      alt=""
                      className="icon m-2"
                    />
                  </div>
                  <div className=''>
                    <span className='fw-bold text-muted text-uppercase'>{booth[0]?.booth_name}</span>
                  </div>
                </div>
                <hr />
              </Card>
            </div>
          </Col>
          <Col md={8} xs={12} className="vh-100">
            <Canvas />
            <div className="d-md-flex flex-row h-10 justify-content-center">
              <Link
                to={{ pathname: `mailto:${booth[0]?.email}` }}
                target="_blank"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icon/envelope.svg`}
                  alt=""
                  className="icon-lg mx-3 bg-transparent"
                />
              </Link>
              <Link to={{ pathname : `${booth?.facebook}`}} target="_blank">
              <img
                src={`${process.env.PUBLIC_URL}/assets/icon/facebook2.svg`}
                alt=""
                className="icon-lg mx-3"
              />
              </Link>
              <Link to={{ pathname : `${booth?.twitter}`}} target="_blank">
              <img
                src={`${process.env.PUBLIC_URL}/assets/icon/twitter.svg`}
                alt=""
                className="icon-lg mx-3"
              />
              </Link>
              <Link to={{ pathname : `${booth?.linkedin}`}} target="_blank">
              <img
                src={`${process.env.PUBLIC_URL}/assets/icon/linkedin2.svg`}
                alt=""
                className="icon-lg mx-3"
              />
              </Link>
              <Link to={{ pathname : `${booth?.instagram}`}} target="_blank">
              <img
                src={`${process.env.PUBLIC_URL}/assets/icon/instagram2.svg`}
                alt=""
                className="icon-lg mx-3"
              />
              </Link>
              <Link to={{ pathname : `${booth?.whatsapp}`}} target="_blank">
              <img
                src={`${process.env.PUBLIC_URL}/assets/icon/Whatsapp.svg`}
                alt=""
                className="icon-lg mx-3"
              />
              </Link>
            </div>
          </Col>
        </Row>
        <MyFooter />
      </Container>
    </>
  );
};

export default Booth;
