import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {LoadCommentChannel} from '../../../../../redux/Actions/Qiscus'
import greeter from "../../../../../emitter";

const Lobby = (props) =>{
    const dispatch = useDispatch()
    const { data: comment } = useSelector((state) => state.LoadCommentChannel);
    const [list, setList] = useState([]);
    
    /* useEffect(()=>{
        const options = {
            roomId: props.room.unique_id,
        };
        dispatch(LoadCommentChannel(props.room.id));
    },[]) */

    const handler = (index) => {
        props.cb2(true);
       /*  props.cb3(props.list[index]); */
    };

    return(
        <div className='overflow-scroll h-100 pb-5 main-session'>
        {list.map((element, index) =>
          element.email != props.auth?.data?.id_user ? (
            <div className="d-flex bd-highlight mb-3">
              <div className="p-2 bd-highlight" onClick={() => handler(index)}>
                {element.active ? (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icon/icon_online.svg`}
                    alt=""
                    className="icon"
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icon/icon_offline.svg`}
                    alt=""
                    className="icon"
                  />
                )}
              </div>
              <div className="p-2 bd-highlight">
                <div>
                  <small>{element.username}</small> |{" "}
                  <small className="text-muted">{element.position}</small>
                </div>
                <div className='d-flex align-items-end'>
                  <span className="card shadow-sm p-2 mx-w-30 message-left me-2">
                    {element.message}
                  </span>
                  <small>{moment(element.timestamp).format('LT')}</small>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-row flex-row-reverse mb-3 me-3">
              <div className='d-flex align-items-end'>
                <small>{moment(element.timestamp).format('LT')}</small>
                <span className="card border-blue shadow-sm p-2 mx-w-30 message-right ms-2">
                  {element.message}
                </span>
              </div>
            </div>
          )
        )}
        </div>
    )
}

export default Lobby