import { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { GetParticipants } from "../../../../../redux/Actions/Chatroom";

const Participants = (props) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
  const { data: participants } = useSelector((state) => state.GetParticipants);

	useEffect(()=>{
		dispatch(GetParticipants(props?.auth?.event_id, setLoading))
	},[])

  const handler = (e) => {
		props.setShow(true)
		props.callback(participants[e])
  };
  
  return (
    <div className="m-2">
      <Form.Group
        controlId="validationFormikUsername"
        className="rounded-pill bg-custom-grey my-3"
      >
        <InputGroup>
          <InputGroup.Text
            id="inputGroupPrepend"
            className="bg-transparent border-0"
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/icon/icon_search.svg`}
              className="icon p-2"
              alt=''
            />
          </InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Search participiant"
            aria-describedby="inputGroupPrepend"
            className="border-0 bg-custom-grey"
            name="username"
          />
          <InputGroup.Text
            id="inputGroupPrepend"
            className="bg-transparent border-0"
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/icon/icon_filter.svg`}
              className="icon rounded-circle bg-custom-blue p-2"
              alt=""
            />
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
      <span className="text-blue fw-bolder open-sans">Participants List</span>
      <div className="overflow-scroll h-100 pb-5 main-session mt-3">
        {participants.map((item, index) =>
          item.user_id != props.auth.auth_id ? (
            <div className="d-flex bd-highlight mb-3 open-sans">
              <div onClick={(e) => handler(index)}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icon/icon_chat.svg`}
                  alt=""
                />
              </div>
              <div className="align-self-center">
                <span className="fw-bold ms-2">{item.user_name}</span>
                <small>
                  - {item.user_job_name} - {item.user_company_name}
                </small>
              </div>
              <div className="ms-auto">
                <img src={`${process.env.PUBLIC_URL}/assets/icon/icon_add_connection.svg`} alt='' onClick={()=>handler(index)}/>
              </div>
            </div>
          ) : (
            <></>
          )
        )}
      </div>
    </div>
  );
};
export default Participants;
