import axios from 'axios';
import Swal from 'sweetalert2';

const getUserSuccess = (res)=>{return {type : 'GET_USER_SUCCESS', payload : res}}
const getUserFailed = (err)=>{return {type : 'GET_USER_FAILED', payload : err}}

const GetUser = (userData, cb)=>{
    return (dispatch) => {
        cb(true);
        return axios({
            url: `${process.env.REACT_APP_API_URL}user/profile/${userData.id}`,
            method: 'GET'
        }).then((res) => {
            if(res.data.statusCode == 200){
                dispatch(getUserSuccess(res.data.data.dataUser));
                cb(false);
            }else{
                Swal.fire({
                    icon: "error",
                    text: `${res.data.statusMessage}`,
                    confirmButtonText: "Confirm",
                    customClass: {
                      confirmButton:
                        "btn btn-danger fw-bolder open-sans rounded-pill py-2 px-4 border-0",
                    },
                    buttonsStyling: false,
                }
                )
            }
        }).catch((err) => {
            dispatch(getUserFailed(err))
            cb(false);
        })
    }
}

const GetBookUserSuccess = (res)=>{return {type : 'GET_BOOK_USER_SUCCESS', payload : res}}
const GetBookUserFailed = (err)=>{return{type : 'GET_BOOK_USER_FAILED', payload : err}}

const GetBookUser = (data,cb) =>{
    return (dispatch)=>{
        cb(true);
        return axios({
            url : `${process.env.REACT_APP_API_URL}user/profile/${data.id}`,
            method : 'GET'
        }).then((res)=>{
            if(res.data.statusCode == 200){
                dispatch(GetBookUserSuccess(res.data.data.dataScheduleUser));
                cb(false);
            }
        }).catch((err)=>{
            dispatch(GetBookUserFailed(err));
            cb(false);
        })
    }
}

const forgotPasswordSuccess = (res)=>{return {type : 'FORGOT_PASSWORD_SUCCESS', payload : res}}
const forgotPasswordFailed = (err) =>{return {type : 'FORGOT_PASSWORD_FAILED', payload : err}}

const UserForgotPassword = (email, cb)=>{
    return(dispatch)=>{
        cb(true);
        return axios({
            url : `${process.env.REACT_APP_API_URL}user/forgot-password`,
            method : 'POST',
            data : email
        }).then((res)=>{
            if(res.data.statusCode == 200){
                localStorage.setItem("myEmail", email.email);
                dispatch(forgotPasswordSuccess(res.data))
                cb(false);
                Swal.fire({
                    icon : 'success',
                    title : 'Sent',
                    text : `${res.data.statusMessage}`,
                    confirmButtonText:'Confirm',
                    customClass: {
                        confirmButton: 'btn btn-blue fw-bolder open-sans rounded-pill py-2 px-4 border-0',
                    },
                    buttonsStyling: false
                }
                )
            }else{
                dispatch(forgotPasswordFailed(res))
                cb(false);
                Swal.fire({
                    icon: "error",
                    text: `${res.data.statusMessage}`,
                    confirmButtonText: "Confirm",
                    customClass: {
                      confirmButton:
                        "btn btn-danger fw-bolder open-sans rounded-pill py-2 px-4 border-0",
                    },
                    buttonsStyling: false,
                });
            }
        }).catch((err)=>{
            dispatch(forgotPasswordFailed(err))
            cb(false);
            Swal.fire({
                icon: "error",
                text: `${err}`,
                confirmButtonText: "Confirm",
                customClass: {
                  confirmButton:
                    "btn btn-danger fw-bolder open-sans rounded-pill py-2 px-4 border-0",
                },
                buttonsStyling: false,
            });
        });
    }
}

const checkEmailSuccess = (res)=>{return {type : 'CHECK_EMAIL_SUCCESS', payload :res}}
const checkEmailFailed = (err)=>{return {type : 'CHECK_EMAIL_FAILED', payload : err}}
const CheckEmail = (email, cb)=>{
    return(dispatch)=>{
        cb(true);
        return axios({
            url :`${process.env.REACT_APP_API_URL}user/validation-email`,
            method : 'POST',
            data : email
        }).then((res)=>{
            dispatch(checkEmailSuccess(res.data));
            cb(false);
        }).catch((err)=>{
            dispatch(checkEmailFailed(err));
            cb(false);
        })
    }
}
//Update profile
const UpdateProfileSuccess = (res)=>{return {type : 'UPDATE_PROFILE_SUCCESS', payload : res}}
const UpdateProfileFailed = (err)=>{return{type : 'UPDATE_PROFILE_FAILED', payload : err}}

const UpdateProfile=(data, token, user_id, cb)=>{
    return (dispatch)=>{
        cb(true);
        return axios({
            method : 'PUT',
            url : `${process.env.REACT_APP_API_URL}user/profile/${user_id}`,
            headers : {Authorization : `Bearer ${token}`},
            data : data
        }).then((res)=>{
            if(res.data.statusCode == 200){
                dispatch(UpdateProfileSuccess(res.data.data));
                cb(false);
                Swal.fire({
                    icon : 'success',
                    title : 'Success',
                    text : `${res.data.statusMessage}`,
                    confirmButtonText:'Confirm',
                    customClass: {
                        confirmButton: 'btn btn-blue fw-bolder open-sans rounded-pill py-2 px-4 border-0',
                    },
                    buttonsStyling: false
                }
                )
            }else{
                Swal.fire({
                    icon : 'error',
                    title : 'Error',
                    text : `${res.data.statusMessage}`,
                    confirmButtonText:'Confirm',
                    customClass: {
                        confirmButton: 'btn btn-danger fw-bolder open-sans rounded-pill py-2 px-4 border-0',
                    },
                    buttonsStyling: false
                }
                )
            }
        }).catch((err)=>{
            dispatch(UpdateProfileFailed(err));
            cb(false);
        })
    }
}
// update password when forgot-password
const updatePasswordSuccess = (res)=>{return {type : 'CHANGE_PASSWORD_SUCCESS', payload : res}}
const updatePasswordFailed = (err)=>{return {type : 'CHANGE_PASSWORD_FAILED', payload : err}}
const UpdatePasswordUser = (data, cb, cb2)=>{
    return (dispatch)=>{
        cb(true);
        return axios({
            url : `${process.env.REACT_APP_API_URL}user/change-password`,
            method : 'POST',
            data : data
        }).then((res)=>{
            dispatch(updatePasswordSuccess(res))
            cb(false);
            Swal.fire({
                icon : 'success',
                title : 'Success',
                text : `${res.data.statusMessage}`,
                confirmButtonText:'Confirm',
                customClass: {
                    confirmButton: 'btn btn-blue fw-bolder open-sans rounded-pill py-2 px-4 border-0',
                },
                buttonsStyling: false
            }
            ).then((result)=>{
                if(result.isConfirmed){
                    cb2.replace('/login');
                }
            });
        }).catch((err)=>{
            dispatch(updatePasswordFailed(err))
            cb(false);
        })
    }
}

const ChangePasswordUserSuccess = (data)=>{return {type : 'CHANGE_PASSWORD_USER_SUCCESS', payload : data}}
const ChangePasswordUserFailed = (err)=>{return{type: 'CHANGE_PASSWORD_USER_FAILED', payload : err}}

const ChangePassword = (data, cb, cb2)=>{
    return(dispatch)=>{
        cb(true);
        return axios({
            method : 'POST',
            url : `${process.env.REACT_APP_API_URL}user/profile/change-password`,
            headers : {Authorization : `Bearer ${data.token}`},
            data : {user_id : data.user_id, old_password : data.old_password, new_password : data.new_password}
        }).then((res)=>{
            if(res.data.statusCode == 200){
                dispatch(ChangePasswordUserSuccess(res.data.data));
                cb(false);
                Swal.fire({
                    icon : 'success',
                    title : 'Success Change Password !',
                    text : `${res.data.statusMessage}`,
                    confirmButtonText:'Confirm',
                    customClass: {
                        confirmButton: 'btn btn-blue fw-bolder open-sans rounded-pill py-2 px-4 border-0',
                    },
                    buttonsStyling: false
                }
                ).then((result)=>{
                    if(result.isConfirmed){
                        cb2.replace('/profile');
                    }
                });
            }else{
                dispatch(ChangePasswordUserFailed(res.data.data));
                cb(false);
                Swal.fire({
                    icon : 'error',
                    title : 'Failed Change Password !',
                    text : `${res.data.statusMessage}`,
                    confirmButtonText:'Confirm',
                    customClass: {
                        confirmButton: 'btn btn-danger fw-bolder open-sans rounded-pill py-2 px-4 border-0',
                    },
                    buttonsStyling: false
                }
                )
            }
        }).catch((err)=>{
            dispatch(ChangePasswordUserFailed(err))
            cb(false);
        })
    }
}

const GetConnectionSuccess = (data) =>{return {type : 'GET_CONNECTION_SUCCESS', payload : data}}
const GetConnectionFailed = (error)=>{return {type : 'GET_CONNECTION_FAILED', payload : error}}

const GetConnections = (userId, cb)=>{
    return (dispatch)=>{
        cb(true);
        axios.get(`${process.env.REACT_APP_API_URL}user/chat/user-data-request-connection/${userId}`).then((res)=>{
            dispatch(GetConnectionSuccess(res.data.data));
            cb(false);
        }).catch((err)=>{
            dispatch(GetConnectionFailed(err));
            cb(false);
        })
    }
}

const ActionRequestSuccess = (data)=>{return {type : 'ACTION_REQUEST_SUCCESS', payload : data}}
const ActionRequestFailed = (err)=>{return {type : 'ACTION_REQUEST_FAILED', payload : err}}

const ActionRequest = (data, cb, cb2)=>{
    return (dispatch)=>{
        cb(true);
       axios({
           method:'PUT',
           url : `${process.env.REACT_APP_API_URL}user/chat/user-confirm-request-connection/${data.user_connection_id}`,
           data : {status_confirm : (data.status)?'Accept':'Reject'}
       }).then((res)=>{
            if(res.data.statusCode == 200){
                dispatch(ActionRequestSuccess(res))
                cb(false);
                cb2(true);
            }
       }).catch((err)=>{
            dispatch(ActionRequestFailed(err))
            cb(false);
            cb2(false);
       })
    }
}

export {GetUser, UserForgotPassword, CheckEmail, UpdatePasswordUser, GetConnections, ActionRequest, GetBookUser, ChangePassword, UpdateProfile};