import { useEffect, useState } from "react";
import { Col, Form, Button, InputGroup } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { MyLoading } from "../../../components";
import { GetUser } from "../../../redux/Actions/Users";
import FormEdit1 from "./FormEdit1";
import FormEdit2 from "./FormEdit2";

const EditProfile = (props) => {
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const { data: profile } = useSelector((state) => state.GetUser);
  const [state, setState] = useState(1);

  const [modifiedData, setModifiedData] = useState({
    email : null, 
    user_gender : null,
    user_dob : null,
    user_phone : null,
    user_linkedin_link : null,
    user_company_name : null,
    user_position_name : null, 
    user_salary : null,
    user_spending : null,
    user_name : null,
    name_card: [],
    photo : []
  });

  useEffect(()=>{
    dispatch(GetUser({ id: props?.auth?.user_id }, setLoading));
  },[])
  useEffect(() => {
    setValue();
  }, [profile]);

  function setValue(){
    setModifiedData({...modifiedData,
      email : profile?.email, 
      user_gender : profile?.user_gender,
      user_dob : profile?.user_dob,
      user_phone : profile?.user_phone,
      user_linkedin_link : profile?.user_linkedin_link,
      user_company_name : profile?.user_company_name,
      user_position_name : profile?.user_position_name, 
      user_salary : profile?.user_salary,
      user_spending : profile?.user_spending,
      user_name : profile?.user_name,
      user_job_name : profile?.user_job_name
    })
  }
  useEffect(() => {
    let poster = document.getElementById("poster");
    let btnAdd = document.getElementById("add");
    let btnDelete = document.getElementById("delete");
    if (modifiedData?.name_card?.length < 1) {
      poster.style.display = "none";
      btnDelete.style.display = "none";
      btnAdd.style.display = "block";
    } else {
      poster.style.display = "block";
      btnAdd.style.display = "none";
      btnDelete.style.display = "block";
    }
  }, [modifiedData]);

  const handleChange = (e) => {
    let poster = document.getElementById("poster");
    poster.src = URL.createObjectURL(e.target.files[0]);
    setModifiedData({
      ...modifiedData,
      name_card: e.target.files,
    });

    poster.onload = function () {
      URL.revokeObjectURL(poster.src);
    };
  };

  return (
    <>
      <Col xs={12} md={4} className="pt-5 card border-bottom-0">
        <div className="d-flex align-items-center flex-column bd-highlight mb-3 h-100 text-center text-uppercase px-3">
          <div className="btn btn-outline-blue text-center open-sans rounded-pill w-50 py-2 mb-4">
            <h5 className="text-blue fw-bold my-auto">PROFILE</h5>
          </div>
          <div
            onMouseOver={() => setDisplay(true)}
            onMouseOut={() => setDisplay(false)}
          >
            {profile?.user_photo !== "" && profile?.user_photo !== null ? (
              <img src={profile?.user_photo} alt="" />
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/assets/icon/icon_profile.png`}
                alt=""
              />
            )}
            <div className="position-absolute top-50 start-50">
              <img
                src={process.env.PUBLIC_URL + "/assets/icon/icon_edit.svg"}
                alt=""
                className={`icon float-end ms-5 pb-3 ${
                  display ? "d-block" : "d-none"
                }`}
              />
            </div>
          </div>
          <div className="my-3 w-100 d-flex flex-column text-center">
              <Form.Group className="my-2 mx-5">
                <InputGroup>
                  <Form.Control
                    onChange={(e) =>
                      setModifiedData({ ...modifiedData, user_name: e.target.value })
                    }
                    defaultValue={modifiedData?.user_name}
                    type="text"
                    placeholder="User name is empty"
                    className={`border-top-0 border-start-0 border-end-0`}
                  />
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    className="bg-transparent border-top-0 border-start-0 border-end-0"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/icon/icon_edit.svg"
                      }
                      alt="..."
                      className="icon-sm"
                    />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            <hr className="my-0 mx-5" />
              <Form.Group className="my-2 mx-5">
                <InputGroup>
                  <Form.Control
                    onChange={(e) =>
                      setModifiedData({ ...modifiedData, user_job_name: e.target.value })
                    }
                    defaultValue={modifiedData?.user_job_name}
                    type="text"
                    placeholder="User job is empty"
                    className={`border-top-0 border-start-0 border-end-0`}
                  />
                  <InputGroup.Text
                    id="inputGroupPrepend"
                    className="bg-transparent border-top-0 border-start-0 border-end-0"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/icon/icon_edit.svg"
                      }
                      alt="..."
                      className="icon-sm"
                    />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
          </div>
          {profile?.user_card_image != null &&
          profile?.user_card_image != "" ? (
            <img
              src={`${profile?.user_card_image}`}
              className="img-fluid"
              alt=""
            />
          ) : (
            <div className="overflow-scroll h-50 w-100">
              <center>
              <Form.Group controlId="formBasicEmail">
                <div class="d-flex flex-column justify-content-center h-75">
                  <img
                    src=".."
                    alt="..."
                    className="me-3 align-content-center background w-75 h-100"
                    id="poster"
                  />

                  <div
                    className="btn btn-sm rounded-30 align-self-center p-3 btn-outline-blue me-3"
                    id="add"
                  >
                    <label>
                      <Form.Control
                        type="file"
                        className="rounded-pill py-3"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => handleChange(e)}
                      />
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/icon/icon_upload.svg`}
                        alt=""
                        className="me-3 icon"
                      />
                      <span className="fw-bolder">Upload</span>
                    </label>
                  </div>

                  <Button
                    className="btn btn-sm rounded-30 px-4 btn-outline-blue align-self-center py-2 d-none"
                    id="delete"
                  >
                    <label>
                      <span className="fw-bolder">Delete</span>
                    </label>
                  </Button>
                </div>
              </Form.Group>
              </center>
            </div>
          )}
        </div>
      </Col>
      <Col className="pt-5 card border-bottom-0 border-start-0 w-100 px-5">
        {state == 1 ? (
          <FormEdit1 profile={modifiedData} setData={setModifiedData}/>
        ) : (
          <FormEdit2
            profile={modifiedData}
            setData={setModifiedData}
          />
        )}
        <div className="d-flex flex-row justify-content-center mt-md-auto my-4">
          <div
            className={`rounded-circle p-2 me-2 cursor-pointer ${
              state == 1 ? "bg-custom-blue" : "bg-secondary"
            }`}
            onClick={() => setState(1)}
          >
            <span className="text-white mx-2">1</span>
          </div>
          <div
            className={`rounded-circle p-2 cursor-pointer ${
              state == 2 ? "bg-custom-blue" : "bg-secondary"
            }`}
          >
            <span className="text-white mx-2" onClick={() => setState(2)}>
              2
            </span>
          </div>
        </div>
        <MyLoading loading={loading} setLoading={setLoading} />
      </Col>
    </>
  );
};

export default EditProfile;
