const initialState = {
    data : [],
    loading : false
}

const GetUser = (state = initialState, action = {}) => {
    switch (action.type) {  
        case 'GET_USER_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'GET_USER_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}

const GetBookUser = (state= initialState, action = {})=>{
    switch(action.type){
        case 'GET_BOOK_USER_SUCCESS':
            return{
                ...state,
                loading : false,
                data : action.payload
            }
        case 'GET_BOOK_USER_FAILED':
            return{
                ...state,
                loading : false,
                data : [],
                error : action.payload
            }
        default :
            return state
    }
}

const GetConnections = (state = initialState, action = {}) => {
    switch (action.type) {  
        case 'GET_CONNECTION_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'GET_CONNECTION_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}

const UserForgotPassword = (state = initialState, action = {}) => {
    switch (action.type) {  
        case 'FORGOT_PASSWORD_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'FORGOT_PASSWORD_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}

const CheckEmail= (state = initialState, action = {}) => {
    switch (action.type) {  
        case 'CHECK_EMAIL_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'CHECK_EMAIL_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}

const UpdatePasswordUser = (state = initialState, action = {}) => {
    switch (action.type) {  
        case 'UPDATE_PASSWORD_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'UPDATE_PASSWORD_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}

const ActionRequest= (state = initialState, action = {}) => {
    switch (action.type) {  
        case 'ACTION_REQUEST_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'ACTION_REQUEST_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}

const SendRequest = (state = initialState, action = {}) => {
    switch (action.type) {  
        case 'SEND_REQUEST_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'SEND_REQUEST_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}

const ChangePassword = (state = initialState, action = {})=>{
    switch(action.type){
        case 'CHANGE_PASSWORD_USER_SUCCESS':
            return{
                ...state,
                loading : false,
                data : action.payload
            }
        case 'CHANGE_PASSWORD_USER_FAILED':
            return{
                ...state,
                loading : false,
                error : action.payload,
                data : []
            }
        default:
            return state
    }
}
const UpdateProfile = (state = initialState, action = {})=>{
    switch(action.type){
        case 'UPDATE_PROFILE_SUCCESS':
            return{
                ...state,
                loading : false,
                data : action.payload
            }
        case 'UPDATE_PROFILE_FAILED':
            return{
                ...state,
                loading : false,
                error : action.payload,
                data : []
            }
        default :
            return state
    }
}
export {GetUser, UserForgotPassword, CheckEmail, UpdatePasswordUser, GetConnections, ActionRequest, SendRequest, GetBookUser, ChangePassword, UpdateProfile}