import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetHome } from "../../redux/Actions/Home";

const MyFooter = (props) => {
  const dispatch = useDispatch();
  const {data} = useSelector((state)=>state.GetHome);
  const [loading, setLoading] = useState(false)
  const {data : authData} = useSelector((state)=>state.UserLogin);

  useEffect(()=>{
    dispatch(GetHome({id_event : 1}, setLoading))
  },[])

  console.log(data)
  return (
    <footer className='shadow-lg'>
      <div className="d-flex align-items-start flex-column bd-highlight mb-3 h-100">
        <Row className="mx-auto px-5 pt-1 w-100">
          <Col xs={12} md={6}>

            <div className="d-flex flex-row align-items-start bd-highlight mt-5">
              <Link to={{ pathname : `${props?.data?.facebook}`}} target="_blank">
              <div className='bg-custom-blue rounded-circle p-2 me-3'>
                <img src={`${process.env.PUBLIC_URL}/assets/icon/icon_facebook.svg`} className='icon' alt=''/>
              </div>
              </Link>

              <Link to={{ pathname : `${props?.data?.instagram}`}} target="_blank">
              <div className='bg-custom-blue rounded-circle p-2 me-3'>
                <img src={`${process.env.PUBLIC_URL}/assets/icon/icon_instagram.svg`} className='icon' alt=''/>
              </div>
              </Link>

              <Link to={{pathname : `mailto:${props?.data?.email}`}} target="_blank">
              <div className='bg-custom-blue rounded-circle p-2 me-3'>
                <img src={`${process.env.PUBLIC_URL}/assets/icon/icon_mail.svg`} className='icon' alt=''/>
              </div>
              </Link>

              <Link to={{ pathname : `${props?.data?.youtube}`}} target="_blank">
              <div className='bg-custom-blue rounded-circle p-2 me-3'>
                <img src={`${process.env.PUBLIC_URL}/assets/icon/icon_youtube.svg`} className='icon' alt=''/>
              </div>
              </Link>

            </div>
          </Col>
          <Col md={6} className="d-none d-md-block h-100">
            <div className="d-flex flex-column align-items-end h-100 bd-highlight mb-3 text-end">
              <span className="p-2 mb-3 bd-highlight w-50 text-blue">
              {props?.data?.address ?? `Bank Indonesia JL. MH Thamrin No 02, Jakarta 10350 Contact
                Center Bank Indonesia (BICARA131) Telp 1500131 (dari dalam dan
                luar negri), bicara@bi.go.id`}
              </span>
            </div>
          </Col>
          
          <div className="d-block d-md-none col-lg-6">
            <div className="d-flex flex-column align-items-start bd-highlight mb-3 text-start">
              <span className="p-2 mb-3 bd-highlight">
                {props?.data?.address ?? `Bank Indonesia JL. MH Thamrin No 02, Jakarta 10350 Contact
                Center Bank Indonesia (BICARA131) Telp 1500131 (dari dalam dan
                luar negri), bicara@bi.go.id`}
              </span>
            </div>
          </div>
        </Row>
        <div className="p-2 bd-highlight mt-3 bg-custom-blue w-100 px-5">
          <div class="d-flex flex-row-reverse bd-highlight">
            <span className="text-white py-2">{`@ ${props?.data?.copyright ?? '2021 Bank Indonesia'}`}</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MyFooter;
