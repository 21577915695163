import { Col, Container, Row } from "react-bootstrap";
import { MyFooter, MyNavbar } from "../../components";
import { UserLogout } from "../../redux/Actions/Auth";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Swal from "sweetalert2";
import { EditProfile, MySchedule, Setting } from "./Components";
import { Link } from "react-router-dom";
import { useEffect } from "react";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Profile = () => {
  const {data : auth} = useSelector((state)=>state.UserLogin);

  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  
  return (
    <>
      <MyNavbar />
      <Container fluid className="vh-100 p-0 open-sans">
        <div className="w-100">
          <Row className="m-0 pt-5">
            <Col xs={12} md={3} className="bg-custom-blue pt-5 vh-100">
              <div className="d-flex align-items-center flex-column bd-highlight mb-3 h-100 text-center text-uppercase px-3">
                <Link
                  to="/profile?menu=edit_profile"
                  className={`p-2 mb-3 bd-highlight cursor-pointer border rounded-pill w-100 no-style ${
                    query.get("menu") == "edit_profile" ? 'border-blue bg-white text-blue' : 'border-white text-white'
                  }`}
                >
                  <span className="fw-bold">Profile</span>
                </Link>

                <Link
                  to="/profile?menu=my_schedule"
                  className={`p-2 mb-3 bd-highlight cursor-pointer rounded-pill border w-100 no-style ${
                    query.get("menu") == "my_schedule" ? 'border-blue bg-white text-blue' : 'border-white text-white'
                  }`}
                >
                  <span className="fw-bold">MY SCHEDULE</span>
                </Link>

                <Link
                  to="/profile?menu=setting"
                  className={`p-2 mb-3 bd-highlight cursor-pointer rounded-pill border w-100 no-style ${
                    query.get("menu") == "setting" ? 'border-blue bg-white text-blue' : 'border-white text-white'
                  }`}
                >
                  <span className="fw-bold">Setting</span>
                </Link>

                <div
                  className="mt-auto p-2 mb-3 bd-highlight cursor-pointer rounded-pill border border-white w-50"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure?",
                      text: "to logout?",
                      icon: "question",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        dispatch(UserLogout());
                        history.replace("/");
                      }
                    });
                  }}
                >
                  <span className="text-white fw-bold">Log Out</span>
                </div>
              </div>
            </Col>
            {query.get("menu") == "edit_profile" ? (
              <EditProfile auth={auth}/>
            ) : query.get("menu") == "my_schedule" ? (
              <MySchedule auth={auth}/>
            ) : query.get("menu") == "setting" ? (
              <Setting auth={auth}/>
            ) : (
              history.replace("/profile?menu=edit_profile")
            )}
          </Row>
          <hr className="m-0 text-blue" />
          <MyFooter />
        </div>
      </Container>
    </>
  );
};

export default Profile;
