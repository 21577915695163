import { Button, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { CheckEmail } from "../../../../redux/Actions/Users";
import { useEffect, useState } from "react";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const RegistrationOne = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    setValue
  } = useForm();

  const [phone, setPhone] = useState(props.data.phone);

  useEffect(()=>{
    setValue("phone", phone);
  },[phone])

  const processRegister = async (data) => {
    props.setData({
      ...props.data,
      name: data.name,
      gender: data.gender,
      email: data.email,
      birth: data.birth,
      phone : phone
    });
    props.setStage(2);
  };

  /* const processMatching = async(e) =>{
    try{
      await dispatch(CheckEmail(e));
      if(email){
        console.log('sama');
      }else{
        console.log('tidak sama');
      }
    }catch(err){
      console.log(err);
    }
  } */
  return (
    <>
      <div className="d-flex flex-row me-auto my-md-4 my-5">
        <Button onClick={history.goBack} className="bg-transparent border-0">
          <img
            src={`${process.env.PUBLIC_URL}/assets/icon/icon_back_arrow.svg`}
            alt=""
            className="icon-lg"
          />
        </Button>
        <h3 className="d-flex align-items-center fw-bold">
          Create your Account
        </h3>
      </div>
      <Form className="w-100 h-100 overflow-scroll" onSubmit={handleSubmit(processRegister)}>
        <div className="p-0  px-0 me-auto px-md-5 px-3 row">
          <Col xs={10} md={10}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className="text-blue fw-bolder">Name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.data.name}
                {...register("name", { required: true, minLength: 4 })}
                placeholder="Type here"
                className={`rounded-pill py-3 ${
                  errors.name ? "is-invalid" : ""
                }`}
              />
              <small className="text-danger fw-bold mt-2 ms-3">
                {errors.name?.type === "required" && "Name is required"}
              </small>
            </Form.Group>
            <Form.Group>
              <Form.Label className="text-blue fw-bolder">Gender</Form.Label>
              <br />
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  {...register("gender", {
                    required: true,
                  })}
                  onClick={()=> props?.setData({...props.data, gender : ('Male')})}
                  checked={props?.data?.gender == 'Male'}
                  value="Male"
                />
                <label className="form-check-label" for="inlineRadio1">
                  Male
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  onClick={() => props?.setData({...props.data, gender : ('Female')})}
                  {...register("gender", {
                    required: true,
                  })}
                  checked={props?.data?.gender == 'Female'}
                  value="Female"
                />
                <label className="form-check-label" for="inlineRadio2">
                  Female
                </label>
              </div>
            </Form.Group>
            <small className="text-danger fw-bold mt-2 ms-3">
              {errors.gender?.type === "required" && "gender is required"}
            </small>

            <Form.Group controlId="formBasicPassword">
              <Form.Label className="text-blue fw-bolder">Email</Form.Label>
              <Form.Control
                type="email"
                defaultValue={props.data.email}
                placeholder="Type Here"
                className={`rounded-pill py-3 ${
                  errors.email ? "is-invalid" : ""
                }`}
                {...register("email", {
                  required: true,
                  minLength: 4,
                  validate: {
                    checkUrl: async (value) =>
                      await axios({
                        url: `${process.env.REACT_APP_API_URL}user/validation-email`,
                        method: "POST",
                        data: { email: value },
                      })
                        .then((res) => {
                          if (res?.data?.statusCode == 400) {
                            return false || res?.data?.statusMessage;
                          } else {
                            return true || res?.data?.statusMessage;
                          }
                        })
                        .catch((err) => {
                          console.log("err", err);
                        }),
                  },
                })}
              />
              <small className="text-danger fw-bold mt-2 ms-3">
                {(errors.email?.type === "required" && "Email is required") ||
                  errors?.email?.message}
              </small>
            </Form.Group>

            <Form.Group>
              <Form.Label className="text-blue fw-bolder">
                Date of Birth
              </Form.Label>
              <Form.Control
                type="date"
                defaultValue={props.data.birth}
                className={`rounded-pill py-3 ${
                  errors.birth ? "is-invalid" : ""
                }`}
                {...register("birth", { required: true })}
              />
              <small className="text-danger fw-bold mt-2 ms-3">
                {errors.birth?.type === "required" && "Birth is required"}
              </small>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-blue fw-bolder">
                Phone Number
              </Form.Label>
              <PhoneInput
                countryCodeEditable={false}
                autoFormat={true}
                country={"id"}
                buttonClass={`border-0 bg-transparent`}
                {...register("phone", {required:true})}
                inputClass={`rounded-pill py-4 w-100 ${
                  errors.phone ? "is-invalid" : ""
                }`}
                placeholde="Enter phone number"
                value={phone}
                onChange={setPhone}
                defaultErrorMessage="Must number phone !"
                autocompleteSearch={true}
                priority={{ id: 0 }}
                copyNumbersOnly={false}
                prefix="+"
                
              />
              <small className="text-danger fw-bold mt-2 ms-3">
                {errors.phone?.type === "required" && "Phone is required"}
              </small>
            </Form.Group>
          </Col>
          <Col xs={2} className="me-auto d-flex align-items-center float-end">
            <div className="d-flex flex-column align-self-auto">
              <Button type="submit" className="bg-transparent border-0">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icon/icon_back_arrow_white.svg`}
                  alt=""
                  className="rounded-circle bg-custom-blue icon-lg p-3"
                />
              </Button>
              <span className="align-self-center d-none d-md-block">
                Next
              </span>
            </div>
          </Col>
        </div>
      </Form>
    </>
  );
};

export default RegistrationOne;
