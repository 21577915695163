import axios from 'axios';
import Swal from 'sweetalert2';

const GetAllBoothSuccess = (data)=>{return {type : 'GET_ALL_BOOTH_SUCCESS', payload : data}}
const GetAllBoothFailed = (err)=>{return {type : 'GET_ALL_BOTH_FAILED', payload : err}}

const GetAllBooth = (event_id,cb)=>{
    return (dispatch)=>{
        cb(true);
        axios.get(`${process.env.REACT_APP_API_URL}user/exhibition-hall/${event_id}`).then((res)=>{
            if(res.data.statusCode == 200){
                dispatch(GetAllBoothSuccess(res.data.data));
                cb(false);
            }
        }).catch((err)=>{
            dispatch(GetAllBoothFailed(err));
            cb(false);
        })
    }
}

const GetBoothSuccess = (data) => {return {type : 'GET_BOOTH_SUCCESS', payload : data}}
const GetBoothFailed = (err) => {return {type : 'GET_BOOTH_FAILED', payload : err}}

const GetBooth = (data, cb)=>{
    return(dispatch)=>{
        cb(true);
        axios.get(`${process.env.REACT_APP_API_URL}user/exhibition-hall/booth/${data.booth_id}`).then((res)=>{
            if(res.data.statusCode == 200){
                dispatch(GetBoothSuccess(res.data.data));
                cb(false);
            }
        }).catch((err)=>{
            dispatch(GetBoothFailed(err));
            cb(false);
        })
    }
}
export {GetAllBooth, GetBooth};