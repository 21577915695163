import axios from "axios"

const GetHomeSuccess = (data) =>{return {type : 'GET_HOME_SUCCESS', payload : data}}
const GetHomeError = (err)=>{return {type : 'GET_HOME_ERROR', payload : err}}

const GetHome = (data)=>{
    return(dispatch)=>{
        return axios.get(`${process.env.REACT_APP_API_URL}user/home-page/${data.id_event}`)
        .then((res)=>{
            dispatch(GetHomeSuccess(res));
        }).catch((err)=>{
            dispatch(GetHomeError(err));
        })
    }
}

const GetAgendaSuccess = (data)=>{return {type : 'GET_AGENDA_SUCCESS', payload : data}}
const GetAgendaFailed = (err) => {return {type : 'GET_AGENDA_FAILED', payload : err}}

const GetAgenda = (data, cb)=>{
    console.log(data.event_id, data.date);
    return (dispatch)=>{
        cb(true);
        return axios.get(`${process.env.REACT_APP_API_URL}user/agenda/${data.event_id}/${data.date}`).then((res)=>{
            if(res.data.statusCode == 200){
                dispatch(GetAgendaSuccess(res.data.data));
                cb(false);
            }else{
                dispatch(GetAgendaFailed(res.data.data))
                cb(false);
            }
        }).catch((err)=>{
            dispatch(GetAgendaFailed(err));
            cb(false);
        })
    }
}

const GetCalendarSuccess = (data)=>{return {type : 'GET_CALENDAR_SUCCESS', payload : data}}
const GetCalendarFailed = (err)=>{return {type : 'GET_CALENDAR_FAILED', payload : err}}

const GetCalendar = (data, cb)=>{
    return (dispatch)=>{
        cb(true);
        return axios.get(`${process.env.REACT_APP_API_URL}user/calendar-agenda/${data.event_id}`).then((res)=>{
            if(res.data.statusCode == 200){
                dispatch(GetCalendarSuccess(res.data.data));
                cb(false);
            }else{
                dispatch(GetCalendarFailed(res.data.statusMessage));
                cb(false);
            }
        }).catch((err)=>{
            dispatch(GetCalendarFailed(err));
            cb(false);
        })
    }
}

const BookAgendaSuccess = (data)=>{return {type : 'BOOK_AGENDA_SUCCESS', payload : data}}
const BookAgendaFailed = (err) =>{return {type : 'BOOK_AGENDA_FAILED', payload : err}}

const BookAgenda = (data, cb, cb2) =>{
    return(dispatch)=>{
        cb(true);
        axios({
            method : 'POST',
            url : `${process.env.REACT_APP_API_URL}user/booking-schedule`,
            headers :{token : data.token},
            data : {user_id : data.user_id, event_schedule_id : data.event_schedule_id},
        }).then((res)=>{
            dispatch(BookAgendaSuccess(res.data.data));
            cb(false);
            /* cb2.push('/profile?menu=my_schedule'); */
        }).catch((err)=>{
            dispatch(BookAgendaFailed(err))
            cb(false);
        })
    }
}

const GetConferenceSuccess = (data)=>{return {type : 'GET_CONFERENCE_SUCCESS', payload : data}}
const GetConferenceFailed = (err)=>{return {type : 'GET_CONFERENCE_FAILED', payload : err}}

const GetConference = (data, cb)=>{
    return (dispatch)=>{
        cb(true);
        return axios.get(`${process.env.REACT_APP_API_URL}user/conference-hall/${data.event_id}`).then((res)=>{
            if(res.data.statusCode == 200){
                dispatch(GetConferenceSuccess(res.data.data));
                cb(false);
            }else{
                dispatch(GetConferenceFailed(res.data.statusMessage));
                cb(false);
            }
        }).catch((err)=>{
            dispatch(GetConferenceFailed(err))
            cb(false);
        })
    }
}

export {GetHome, GetAgenda, GetCalendar, BookAgenda, GetConference};