import axios from "axios";
import Swal from "sweetalert2";

const GetParticipantsSuccess = (data)=>{return {type : 'GET_PARTICIPANTS_SUCCESS', payload : data}}
const GetParticipantsFailed = (error) => {return {type : 'GET_PARTICIPANTS_FAILED', payload : error}}

const GetParticipants = (roomId, cb)=>{
    return (dispatch)=>{
        cb(true);
        axios.get(`${process.env.REACT_APP_API_URL}user/chat/user-participant/${roomId}`).then((res)=>{
            if(res.data.statusCode == 200){
                dispatch(GetParticipantsSuccess(res.data.data));
                cb(false);
            }
        }).catch((err)=>{
            dispatch(GetParticipantsFailed(err));
            cb(false);
        })
    }
}

const SendRequestSuccess = (data)=>{return {type :'SEND_REQUEST_SUCCESS', payload : data}}
const SendRequestFailed = (error)=>{return{type : 'SEND_REQUEST_FAILED', payload : error}}

const SendRequest = (data, cb)=>{
    return (dispatch)=>{
        cb(true);
        axios({
            method : 'POST',
            url : `${process.env.REACT_APP_API_URL}user/chat/user-request-connection`,
            data : data
        }).then((res)=>{
            if(res.data.statusCode == 200){
                Swal.fire({
                    icon : 'success',
                    title : 'Success',
                    text : `${res.data.statusMessage}`,
                    confirmButtonText:'Confirm',
                    customClass: {
                        confirmButton: 'btn btn-blue fw-bolder open-sans rounded-pill py-2 px-4 border-0',
                    },
                    buttonsStyling: false
                })
                dispatch(SendRequestSuccess(res.data.data));
                cb(false);
            }else{
                Swal.fire({
                    icon : 'error',
                    title : 'Error',
                    text : `${res.data.statusMessage}`,
                    confirmButtonText:'Confirm',
                    customClass: {
                        confirmButton: 'btn btn-danger fw-bolder open-sans rounded-pill py-2 px-4 border-0',
                    },
                    buttonsStyling: false
                })
                dispatch(SendRequestFailed(res.data.data));
                cb(false);
            }
        }).catch((err)=>{
            dispatch(SendRequestFailed(err));
            cb(false);
        })
    }
}


export {GetParticipants, SendRequest}