const initialState = {
    data : [],
    loading : false
}

export const JoinChannel = (state = initialState, action = {})=>{
    switch (action.type) {  
        case 'JOIN_CHANNEL_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'JOIN_CHANNEL_FAILED': 
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}

export const AddParticipiant = (state = initialState, action = {})=>{
    switch (action.type) {  
        case 'ADD_PARTICIPIANT_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'ADD_PARTICIPIANT_FAILED': 
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}
export const GetParticipiants = (state = initialState, action = {})=>{
    switch (action.type) {  
        case 'GET_PARTICIPIANT_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'GET_PARTICIPIANT_FAILED': 
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}
export const CreateUser = (state = initialState, action = {})=>{
    switch (action.type) {  
        case 'CREATE_USER_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'CREATE_USER_FAILED': 
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}

export const SendMessage = (state = initialState, action = {})=>{
    switch (action.type) {  
        case 'SEND_MESSAGE_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'SEND_MESSAGE_FAILED': 
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}

export const LoadCommentChannel = (state = initialState, action = {})=>{
    switch (action.type) {  
        case 'LOAD_COMMENT_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'LOAD_COMMENT_FAILED': 
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}