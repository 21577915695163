/* eslint-disable react/jsx-pascal-case */
import { Container, Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MyNavbar, MyButton, MyFooter } from "../../../components";
import { useDispatch } from "react-redux";
import { UpdatePasswordUser } from "../../../redux/Actions/Users";
import { useState } from "react";
import { MyLoading } from "../../../components";
import { useHistory } from "react-router";

const UpdatePassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const email = localStorage.getItem('myEmail');
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  
  const processForgot = (data) => {
    dispatch(UpdatePasswordUser({email : email, password : data.password}, setLoading, history));
  };
  return (
    <>
      <MyNavbar />
      <Container fluid className="vh-100 mt-5 p-0">
        <Row className="w-100 p-0">
          <Col xs={0} md={5} className="d-none d-md-block">
            <div className="side-img">
              <div className="cover"></div>
            </div>
          </Col>
          <Col xs={12} md={7} className="d-flex flex-column open-sans p-md-5">
            <div className="p-0 w-100 mx-auto px-0 ms-4 mx-md-3 mt-5">
              <h2 className="fw-bolder ">Create New Password</h2>
              <span>
                Your new password must be different from previous used passwords
              </span>
              <Form onSubmit={handleSubmit(processForgot)} className="mt-5">
                <Form.Group className="mb-3">
                  <Form.Label className="text-blue fw-bolder">
                    Password
                  </Form.Label>
                  <Form.Control
										{...register("password",  {required : true, minLength : 8, pattern : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/})}
                    type="password"
                    placeholder="Type here"
                    className={`rounded-pill py-3 ${errors.password ? 'is-invalid' : ''}`}
                  />
									<small className='text-danger fw-bold mt-2 ms-3'>
                    {
                      errors.password && 'Password must contains *Min 8 character *Min. 1 capital letters *Number'
                    }
                    </small>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="text-blue fw-bolder">
                    Confirm Password
                  </Form.Label>
                  <Form.Control
										{...register("re_password",{required : true, validate : (value)=>{return value=== watch('password');}})}
                    type="password"
                    placeholder="Type here"
                    className={`rounded-pill py-3 ${errors.re_password ? 'is-invalid' : ''}`}
                  />
										<small className='text-danger fw-bold mt-2 ms-3'>
                    {
                      errors.re_password?.type == 'validate' && "The passwords do not match" || errors.re_password?.type == 'required' && "Re-password can`t be empty"
                    }
                    </small>
                </Form.Group>

                <div className="d-flex justify-content-center">
                  <MyButton.btnBlue
                    type="submit"
                    value="Send"
                    style="py-3 w-25"
                  />
                </div>
              </Form>
            </div>
          </Col>
        </Row>
        <hr className="m-0" />
        <MyFooter/>
      </Container>
      <MyLoading loading={loading} setLoading={setLoading}/>   
    </>
  );
};

export default UpdatePassword;
