import { useEffect, useState } from "react";

const Navigator = (props) => {
  function reset() {
    const networking = document.getElementById("networking");
    const participiant = document.getElementById("participiant");
    const connection = document.getElementById("connection");
    const chat = document.getElementById("chat");

    networking.classList.remove("active");
    networking.children[0].classList.remove("text-white");

    participiant.classList.remove("active");
    participiant.children[0].classList.remove("text-white");

    connection.classList.remove("active");
    connection.children[0].classList.remove("text-white");

    chat.classList.remove("active");
    chat.children[0].classList.remove("text-white");
  }

  useEffect(() => {
    const networking = document.getElementById("networking");
    const participiant = document.getElementById("participiant");
    const connection = document.getElementById("connection");
    const chat = document.getElementById("chat");
    switch (props.menu) {
      case 1:
        reset();
        networking.classList.add("active");
        networking.children[0].classList.add("text-white");
        break;
      case 2:
        reset();
        participiant.classList.add("active");
        participiant.children[0].classList.add("text-white");
        break;
      case 3:
        reset();
        connection.classList.add("active");
        connection.children[0].classList.add("text-white");
        break;
      case 4:
        reset();
        chat.classList.add("active");
        chat.children[0].classList.add("text-white");
        break;
      default:
        break;
    }
  }, [props.menu]);

  return (
    <>
      <div className="d-flex flex-row justify-content-between bd-highlight m-0 m-md-2 bg-custom-grey rounded-pill navigation open-sans">
        <div
          className="py-1 my-1 px-2 text-center text-secondary cursor-pointer"
          id="networking"
          onClick={() => props.setMenu(1)}
        >
          <small className='px-2 fw-bolder'>Networking Lounge</small>
        </div>
        <div
          className="py-1 px-2 my-1 text-center text-secondary cursor-pointer"
          id="participiant"
          onClick={() => props.setMenu(2)}
        >
          <small className='px-2 fw-bolder'>Participants List</small>
        </div>
        <div
          className="py-1 px-2 my-1 text-center text-secondary cursor-pointer"
          id="connection"
          onClick={() => props.setMenu(3)}
        >
          <small className='px-2 fw-bolder'>My Connection</small>
        </div>
        <div
          className="py-1 px-2 my-1 text-center text-secondary cursor-pointer"
          id="chat"
          onClick={() => props.setMenu(4)}
        >
          <small className='fw-bolder px-2'>My chat</small>
        </div>
      </div>
    </>
  );
};

export default Navigator;
