import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GetBookUser } from "../../../redux/Actions/Users";
import moment from 'moment';
import { Link } from "react-router-dom";

const MySchedule = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {data : schedule} = useSelector((state)=>state.GetBookUser);

  useEffect(()=>{
    dispatch(GetBookUser({id : props?.auth?.user_id},setLoading))
  },[])

  console.log(schedule);
  return (
    <Col className="pt-5 vh-100 ps-5 open-sans">
      <div className="px-3 rounded-pill border border-blue bg-white w-25 py-2 text-center">
        <span className="text-blue fw-bolder">MY SCHEDULE</span>
      </div>
      {schedule && schedule?.map((element)=>(
        <>
        <div className='d-flex flex-row mt-3 w-75'>
          <div className='me-2'>
            <img src={`${process.env.PUBLIC_URL}/assets/icon/icon_calendar.svg`} alt='' className='icon'/>
          </div>
          <div className='d-flex flex-column'>
            <span className='fw-bold'>{moment(element?.start_time, 'hh.mm').format('LT')} - {moment(element?.end_time, 'hh.mm').format('LT')}</span>
            <small>{element?.event_name}</small>
          </div>
          <div className='ms-auto'>
            <Link to='/conference-hall' className='btn btn-blue rounded-pill'>
              <small className='fw-bold'>GO TO CONFERENCE HALL</small>
            </Link>
          </div>
        </div>
        <hr className='mt-0 mb-5'/>
        </>
      ))}
    </Col>
  );
};

export default MySchedule;
