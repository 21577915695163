import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Canvas = (props) => {
  const [show, setShow] = useState(false);

  return (
    <div>
      <div className="vh-100 banner3">
        <div className="d-flex flex-column justify-content-end h-100 w-100 px-5 py-5">
          <div className="d-flex flex-row h-50">
            {props?.booth?.boothExhibitionHall &&
              props?.booth?.boothExhibitionHall.map((element, index) => (
                <Card
                  className="border-0 bg-transparent h-75 w-20"
                  onMouseOver={() => setShow(true)}
                  onMouseOut={() => setShow(false)}
                >
                  <Card
                    className={`position-absolute top-0 start-0 h-100 w-75 py-3 rounded-30 ${
                      show ? "d-block" : "d-none"
                    }`}
                  >
                    <div className="d-flex flex-column h-100">
                      <center>
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/icon/icon_booth.svg`}
                          alt=""
                          className="icon-lg mt-2"
                        />
                      </center>
                      <center>
                        <span className="text-center">
                          {element.booth_name}
                        </span>
                      </center>
                      <center className="mt-auto">
                        <Link
                          to={`/booth?no_booth=${element.booth_id}`}
                          className="mx-auto btn btn-blue rounded-pill"
                        >
                          VISIT
                        </Link>
                      </center>
                    </div>
                  </Card>
                </Card>
              ))}
            {/*  <Card className="border-blue border-blue bg-transparent h-75 w-20" onMouseOver={()=>pointerDown(1)}>
              
            </Card>
            <Card className="border-blue border-blue bg-transparent h-75 w-20 mx-3" onPointerDown={()=>pointerDown(2)}></Card>
            <Card className="border-blue border-blue bg-transparent h-75 w-20 mx-3" onPointerDown={()=>pointerDown(3)}></Card>
            <Card className="border-blue border-blue bg-transparent h-75 w-20 mx-3" onPointerDown={()=>pointerDown(4)}></Card>
            <Card className="border-blue border-blue bg-transparent h-75 w-20 mx-3" onPointerDown={()=>pointerDown(5)}></Card> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Canvas;
