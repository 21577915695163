import moment from "moment";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GetAgenda } from "../../../redux/Actions/Home";

const SideBar = (props) => {
  const dispatch = useDispatch();
  const { data: agenda } = useSelector((state) => state.GetAgenda);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(
      GetAgenda(
        {
          event_id: props?.auth?.event_id,
          date: moment().format("YYYY-MM-DD"),
        },
        setLoading
      )
    );
  }, []);
  return (
    <>
      <div className="d-flex flex-column h-100">
        <div className="line my-5">
          <span className="px-3 text-blue fw-bolder bg-white py-2 border rounded-pill">
            Conference Hall
          </span>
        </div>
        <div className="d-flex flex-column px-5 h-75 bg-light">
          <h5 className="text-center text-blue fw-bolder open-sans">
            EVENT SCHEDULE
          </h5>
          <hr className="m-0" />
          <div className="h-75 overflow-scroll">
            {loading ? (
              <span className="text-blue text-center">Loading...</span>
            ) : agenda.length > 0 ? (
              agenda.map((element, index) => (
                <>
                  <div className="my-2 d-flex justify-content-center">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icon/icon_calendar.svg`}
                      className="icon-sm"
                      alt=""
                    />
                    <small className="align-self-center ms-2">
                      {" "}
                      09.00 AM - 12.00 PM{" "}
                    </small>
                  </div>
                  <Card className="h-50 bg-white mb-2 border-0 border-radius-20 shadow-lg"></Card>
                </>
              ))
            ) : (
              <center><span className="text-blue">no events today</span></center>
            )}
          </div>
          <span className="text-center">pagination</span>
        </div>
      </div>
    </>
  );
};

export default SideBar;
