const initialState = {
    data : [],
    loading : false
}

const GetAllBooth = (state = initialState, action = {}) => {
    switch (action.type) {  
        case 'GET_ALL_BOOTH_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'GET_ALL_BOOTH_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}

const GetBooth = (state = initialState, action = {}) => {
    switch (action.type) {  
        case 'GET_BOOTH_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload
            }    
        case 'GET_BOOTH_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: []
            }    
        default:
            return state
    }
}
export {GetAllBooth, GetBooth};