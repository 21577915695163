import qiscus from '../../qiscus';

const JoinChannelSuccess = (data) => {
  return { type: "JOIN_CHANNEL_SUCCESS", payload: data };
};
const JoinChannelFailed = (err) => {
  return { type: "JOIN_CHANNEL_FAILED", payloadd: err };
};

const JoinChannel = (data) => {
  return (dispatch) => {
    if(qiscus.isLogin){
      qiscus.
      getOrCreateRoomByChannel(data.uniqueId, data.name, data.avatarURL)
      .then(function (room) {
        dispatch(JoinChannelSuccess(room));
        dispatch(AddPartcipiant({roomId : room.id, userId : data.auth.user.email}))
			})
      .catch(function (error) {
				dispatch(JoinChannelFailed(error));
			}); 
    }else{
      console.log('gk login')
    }
  };
};

const AddPartcipiantSuccess = (data) =>{
  return {type : "ADD_PARTICIPIANT_SUCCESS", payload : data};
}
const AddParticipiantFailed = (err)=>{
  return {type : "ADD_PARTICIPIANT_FAILED", payload : err}
}
const AddPartcipiant = (data)=>{
  return (dispatch)=>{
    if(qiscus.isLogin){
      qiscus.addParticipantsToGroup(data.roomId, [data.userId]).then((users)=>{
        dispatch(AddPartcipiantSuccess(users));
      }).catch((err)=>{
        dispatch(AddParticipiantFailed(err));
      })
    }
  }
}
const CreateUserSuccess = (data)=>{return {type : 'CREATE_USER_SUCCESS', payload : data}};
const CreateUserFailed = (err)=>{return{type : 'CREATE_USER_FAILED', payload : err}};

const CreateUser = (userData, roomData)=>{
	return (dispatch)=>{
	  qiscus.setUser(userData?.data?.id_user, userData?.data?.token, 'displayName', `${process.env.PUBLIC_URL}/assets/icon/icon_chat.svg`, {})
    .then(function (authData) {
      dispatch(CreateUserSuccess(authData));
      qiscus.publishOnlinePresence(true);
      dispatch(JoinChannel({uniqueId : roomData.uniqueId, name : roomData.name, avatarURL : roomData.avatarURL, auth : authData}));
    })
    .catch(function (error) {
      dispatch(CreateUserFailed(error));
    })
	}
}

const SendMessageSuccess = (data)=>{return {type : 'SEND_MESSAGE_SUCCESS', payload : data}};
const SendMessageFailed = (err)=>{return {type : 'SEND_MESSAGE_FAILED', payload : err}};

const SendMessage = (message, cb)=>{
	return (dispatch)=>{
		cb(true);
    if(qiscus.isLogin){
      qiscus.sendComment(message.room_id,
        message.message,
        message.unique_id,
        message.type,
        message.payload,
        message.extras)
      .then(function (comment) {
          dispatch(SendMessageSuccess(comment));
      })
      .catch(function (error) {
          dispatch(SendMessageFailed(error));
      })
    }
	}
}

const LoadCommentChannelSuccess = (data)=>{return {type : 'LOAD_COMMENT_SUCCESS', payload : data}};
const LoadCommentChannelFailed = (err) => {return {type : 'LOAD_COMMENT_FAILED', payload : err}};

const LoadCommentChannel = (uniqueId)=>{
  return (dispatch)=>{

   if(qiscus.isLogin){
    qiscus.loadComments(uniqueId)
       .then(function (comments) {
           dispatch(LoadCommentChannelSuccess(comments));
       })
       .catch(function (error) {
          dispatch(LoadCommentChannelFailed(error));
       })
   }
  }
}

const GetParticipiantSuccess = (data) =>{ return{type : 'GET_PARTICIPIANT_SUCCESS', payload : data}}
const GetParticipiantFailed = (err) =>{return {type : 'GET_PARTICIPIANT_FAILED', payload : err}}

const GetParticipiant = (data)=>{
  return(dispatch)=>{
    var roomUniqueId = data.roomId;
    var page = 1;
    var limit = 20;
    if(qiscus.isLogin){
      qiscus.getParticipants(roomUniqueId, page, limit)
      .then(function (participants){
        dispatch(GetParticipiantSuccess(participants.participants));
      })
      .catch(function (error) {
        dispatch(GetParticipiantFailed(error))
    })
    }
  }
}
export { JoinChannel, CreateUser, SendMessage, LoadCommentChannel, AddPartcipiant, GetParticipiant};