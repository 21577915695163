/* eslint-disable react/jsx-pascal-case */
import { Card } from "react-bootstrap";
import {useState, useEffect} from 'react';
import MyButton from '../../../components/MyButton';

const AddRequest = (props) => {
	console.log(props.data);
	const [show, setShow] = useState(props.show);
	const [state, setState] = useState(props.state);

	useEffect(()=>{
		props.show ? setShow(true) : setShow(false);
		setState(props.state);
	},[props]);

	useEffect(()=>{
		const component = document.getElementById('detail');
		show ? component.style.display = 'block' : component.style.display = 'none';
	},[show])

	useEffect(()=>{
		console.log(state);
	},[state])
  return (
    <div id='detail'>
			{(state == 0)?(
				<Card className='rounded-40 w-100'>
        <Card.Body>
          <div className="d-flex flex-row bd-highlight mb-3">
            <div className="p-2 bd-highlight">
							{(props.data.isOnline)?(
								<img src={`${process.env.PUBLIC_URL}/assets/icon/icon_online.svg`} alt=''/>
							):(
								<img src={`${process.env.PUBLIC_URL}/assets/icon/icon_offline.svg`} alt=''/>
							)
							}
						</div>
            <div className="p-2 bd-highlight w-100">
								<small className='fw-bolder'>{props.data.username}</small>
								<hr className='m-0'/>
								<small className='text-muted'>{props.data.position}</small>
						</div>
          </div>
					<center><MyButton.btnBlue value='Connect' style='px-5' onClick={()=>setState(1)}/></center>
        </Card.Body>
      </Card>
			):(state == 1)?(
				<Card className='rounded-40 w-100'>
        <Card.Body>
          <div className="d-flex flex-row bd-highlight mb-3">
            <div className="p-2 bd-highlight">
							{(props.data.isOnline)?(
								<img src={`${process.env.PUBLIC_URL}/assets/icon/icon_online.svg`} alt=''/>
							):(
								<img src={`${process.env.PUBLIC_URL}/assets/icon/icon_offline.svg`} alt=''/>
							)
							}
						</div>
            <div className="p-2 bd-highlight w-100">
								<small className='fw-bolder'>{props.data.name}</small>
								<hr className='m-0'/>
								<small className='text-muted'>{props.data.position}</small>
						</div>
          </div>
					<center className='my-3'>
						<div><span>Add personal notes to <span className='fw-bolder'>{props.data.name}</span></span></div>
					</center>
					<center className='d-flex justify-content-between'>
						<MyButton.btnBlue value='Send' style='fw-100 mx-2'/>
						<MyButton.btnBlueOutline value='Add Notes' style='fw-100 mx-2' onClick={()=>setState(2)}/>
					</center>
        </Card.Body>
      </Card>
			):(
				<Card className='rounded-40 w-100'>
        <Card.Body>
          <div className="d-flex flex-row bd-highlight mb-3">
            <div className="p-2 bd-highlight">
							{(props.data.isOnline)?(
								<img src={`${process.env.PUBLIC_URL}/assets/icon/icon_online.svg`} alt=''/>
							):(
								<img src={`${process.env.PUBLIC_URL}/assets/icon/icon_offline.svg`} alt=''/>
							)
							}
						</div>
            <div className="p-2 bd-highlight w-100">
								<small className='fw-bolder'>{props.data.name}</small>
								<hr className='m-0'/>
								<small className='text-muted'>{props.data.position}</small>
						</div>
          </div>
					<div>
						<textarea placeholder='Type your message here' className='form-control mb-3 rounded-30'/>
					</div>
					<center><MyButton.btnBlue value='Send' style='px-5'/></center>
        </Card.Body>
				</Card>
			)}
     
    </div>
  );
};
export default AddRequest;
